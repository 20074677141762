<template>
  <Headline :subtitle="'Wir navigieren Euch durch den Wandel'" :seoStyle2="true">Change Management</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <div class="lg:text-center">
        <p class="inline-block p max-w-sm pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Nichts ist so beständig wie der Wandel.”</i> ― Heraklit von Ephesos, Philosoph, 520 v. Chr.</p>
      </div>
      <div class="lg:flex">
        <div class="lg:w-7/12 2xl:w-6/12 2xl:pr-16 lg:mt-8 2xl:mt-20">
          <h3 class="h3 title">Change Management mit Softwerkstatt</h3>
          <p class="my-4">Als Change Begleiter betonen wir stets, dass jeder Veränderungsprozess FÜR den Menschen gemacht ist. Wir zielen darauf ab, Workflows zu verbessern, zukunftssichere Arbeitsplätze zu schaffen und die interne Kommunikation zu optimieren. Wir sorgen dafür, dass alle Akteure einbezogen werden und die ganze Crew mit positiver Einstellung den Wandel unterstützt.</p>
          <p class="my-4">Als Change Manager behalten wir stets den Überblick und gehen strategisch vor, um die vielen Schritte zum Ziel erfolgreich zu gestalten. Auf diese Weise ist der Wandel kein abstrakter Zustand in der Zukunft, sondern wird aktiv in den Arbeitsalltag integriert.</p>
        </div>
        <div class="w-10/12 lg:w-5/12 2xl:w-6/12 2xl:px-8">
          <img src="./../assets/img/illu2.webp" class="rounded-xl" alt="Maßgeschneiderte Software Vorteile: Anpassungen, Integration und Kosten/Nutzen" />
        </div>
      </div>
    </div>

    <Container class="mt-16">
      <img src="./../assets/img/icons/hand_people.png" class="mx-auto h-16 mb-4" alt="">
      <p class="px-4 sm:px-0 w-full md:max-w-4xl mx-auto text-left sm:text-center">„Der Fokus des Change Managements liegt auf dem Managen eines Changes und nicht dem Durchführen von inhaltlichen Aufgaben im Rahmen eines Changes.“ - Gareis</p>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      
      <div class="md:flex">
        <div class="w-full md:w-7/12 2xl:w-6/12">
            <h2 class="h2">Messbarer Erfolg</h2>
            <p class="my-4">Change Management Prozesse sind ein essenzielles Instrument, um Unternehmen agiler und innovativer für die Zukunft aufzustellen. Sie fördern einerseits die Innovationskraft und Wettbewerbsfähigkeit und errichten auf der anderen Seite einen zukunftsfähigen Arbeitsplatz.</p>
            <p class="my-4">Mitarbeitende werden aktiv in den Wandel eingebunden, was nicht nur die Zufriedenheit steigert, sondern auch die Bindung ans Unternehmen stärkt.</p>
            <p class="my-4">Studien belegen, dass mit professionellem Change Management eine 6x höhere Wahrscheinlichkeit besteht, Projektziele zu erreichen und es 5x wahrscheinlicher ist, den Zeitplan einzuhalten.</p>
        </div>
        <div class="w-8/12 sm:w-6/12 md:w-5/12 2xl:w-6/12 md:pl-4 lg:pl-16 2xl:px-32 mx-auto">
          <div class="relative group">
            <img src="./../assets/img/illu3_heightholder.png" alt="" class="object-contain w-full" />
            <img src="./../assets/img/illu3b.webp" alt="" class="absolute left-5 -top-5 transform transition-all group-hover:left-0 group-hover:top-0 z-10" />
            <img src="./../assets/img/illu3a.webp" alt="" class="absolute left-0 top-0 " />
          </div>
        </div>
      </div>

      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-8 2xl:mt-16">
        <Timeline :headline="'Unsere Leistungen'" :desc="''" :text="timelineText"></Timeline>
      </div>
      
    </div>

    <Container class="mt-16">
        <div class="lg:flex">
          <div class="w-8/12 sm:w-6/12 lg:w-5/12 xl:pr-16 2xl:px-16">
            <img src="./../assets/img/illu4.webp" alt="" class="rounded-xl mb-8" />
          </div>
          <div class="lg:w-7/12 2xl:px-16 2xl:mt-4 text-left">
            <h3 class="h3 title">Die Change-Begleiter</h3>
            <p class="my-4">Unsere qualifizierten Change-Begleiter unterstützen Euch dabei, Veränderungsprozesse erfolgreich zu implementieren. Sie zeichnen sich durch herausragende Fähigkeiten in Kommunikation und Methodik aus und verstehen es, Euer Team zu motivieren und durch den Wandel zu führen.</p>
            <p class="my-4">Eine Kombination aus Webinaren, Workshops und Schulungen für Führungskräfte und Mitarbeitende ermöglicht es uns, einen ganzheitlichen Ansatz für Change Management Prozesse anzubieten und sicherzustellen, dass erfolgreicher  Wandel nachhaltig in Eurem Unternehmen umgesetzt wird.</p>
          </div>
        </div>
    </Container>
    
    <p class="px-4 sm:px-0 w-full md:max-w-4xl mx-auto text-left sm:text-center">„Unter Change wird die kontinuierliche oder diskontinuierliche Entwicklung einer Organisation verstanden, in der sie verändert wird. Es wird davon ausgegangen, dass bei einem Change die sozialen Strukturen und Stakeholderbeziehungen verändert werden. Es handelt sich daher um einen sozialen Change.“ - Gareis</p>
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <div class="text-center">
        <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Kontakt aufnehmen</CtaButton>
      </div>
      <ContactInfos />
    </div>


  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'
import ContactInfos from './../views/ContactInfos.vue'
import CtaButton from './../views/CtaButton.vue'
import Timeline from './../views/Timeline.vue'

export default {
  name: 'Experten',
  components: {
    Headline,
    Container,
    ContactInfos,
    CtaButton,
    Timeline
  },
  data: () => {
    return {
      timelineText: [
        'Ist-Situation Analyse: Rausfinden wo Wir stehen und wo es Chancen gibt',
        'Zielgruppenanalyse - Einschätzen der Ansprüche unserer Kunden',
        'Stakeholderanalyse - Leistungen besser auf Bedürfnisse zuschneiden',
        'Implementierung datengetriebener Prozesse und KPIs',
        'Entwicklung disruptiver Geschäftsmodelle ',
        'Big Picture, wo die Reise hingeht, damit Ihr Unternehmen zukunftsfähig bleibt',
        'Beratung, wie Abläufe automatisiert werden können durch customized Software'
      ]
    }
  },
  methods: {
    onClick() {
      this.$router.push({name: 'contact'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>
</style>