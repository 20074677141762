<template>
  <div class="contact mt-4 md:mt-20 md:flex md:justify-between text-left lg:border-t lg:pt-16" style="border-color: #22F0E3">
    <div class="text-xl sm:text-4xl mb-2 text-left">Persönlich kennenlernen?</div>
    <div>
      <img src="./../assets/img/icons/flasche.svg" class="h-6 md:h-7 inline-block md:align-top ml-2 md:ml-0 mr-2 mt-4 pr-2 md:pr-6 relative -top-3 md:top-0" alt="">
      <div class="p inline md:inline-block">
        <div class="inline md:block">Schreibt uns </div>eine <a href="mailto:moin@softwerkstatt.de">Flaschenpost</a>
      </div>
    </div>
    <div>
      <img src="./../assets/img/icons/telefon.svg" class="h-8 md:h-12 inline-block md:align-top ml-2 md:ml-0 mr-2 md:pr-4 relative top-0 md:top-2" alt="">
      <div class="p inline md:inline-block">
        <div class="inline md:block">Oder ruft durch: </div>
        <a href="tel:040800084851">040 / 800 084 851</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfos',
  methods: {
  }
}
</script>

<style scoped>
h2, h3 {
  margin-top: 1.0rem;
}
p, ul {
  margin-top: 1.0rem;
}
a {
  text-decoration: none;
  font-weight: bold;
}
</style>