<template>
  <Headline :subtitle="'Legal Notice'">Impressum</Headline>
  <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl pt-4 md:pt-8 px-4 lg:px-0 pb-32">
    <h3 class="h2">Impressum</h3>
    <div class="flex">
      <p class="flex-1">
        Softwerkstatt GmbH
        <br>Am Sandtorkai 77
        <br>20457 Hamburg
        <br>
        <br>Telefon: 040 / 800 084 851
        <br>E-Mail: <a href="mailto:moin@softwerkstatt.de">moin@softwerkstatt.de</a>
        <br>
        <br>Geschäftsführer: Dario D. Müller
        <br>Amtsgericht Hamburg
        <br>Handelsregister: HRB 169470
        <br>USt-ID: DE343792972
      </p>
    </div>
    <p class="h3">Haftungsausschluss (Disclaimer)</p>
    <p class="h3">Haftung für Inhalte</p>
    <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
    <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
    <h3 class="h3">Haftung für Links</h3>
    <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Betreiber verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
    <p class="h3">Urheberrecht</p>
    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Solltet Ihr trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>

    <CtaButton class="mt-8 mx-auto bg-gray-100 border-gray-100 hover:border-gray-500" @clicked="onClickCta">Fragen oder Anmerkungen?</CtaButton>
    <Modal ref="modal" />
  </div>
</template>

<script>
import CtaButton from './../views/CtaButton.vue'
import Modal from './../views/Modal.vue'
import Headline from './../views/Headline.vue'

export default {
  name: 'Legal',
  components: {
    CtaButton,
    Headline,
    Modal
  },
  methods: {
    onClickCta() {
      this.$refs.modal.open('Fragen oder Anmerkungen?')
    },
  }
}
</script>

<style scoped>
h3, h3, .h2, .h3 {
  margin-top: 1.0rem;
  margin-bottom: -0.5rem;
  font-weight: 700;
  font-size: 1.8em;
}
p {
  margin: 0.5em 0;
}
</style>