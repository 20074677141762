<template>
  <h2 class="h1 mx-auto">{{ headline }}</h2>
  <div class="text-center" v-if="desc">
    <p class="inline-block p max-w-3xl mx-auto">{{ desc }}</p>
  </div>
  <div class="relative mt-10 my-24 hidden sm:block">
    <ul class="relative z-10 flex flex-wrap 2xl:mx-14">
      <li @mouseover="overItem(1)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 1 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/gluehbirne.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(2)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 2 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/konzept.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(3)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 3 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/werkzeug.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(4)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 4 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/entscheidung.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(5)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 5 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/code.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(6)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 6 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/schild.svg" class="h-full mx-auto" alt=""></div></li>
      <li @mouseover="overItem(7)" :class="'h-24 flex-1 transform transition-transform ' + (highlight == 7 ? 'scale-150' : 'opacity-80')"><div class="h-24 rounded-full p-5 mx-auto"><img src="./../assets/img/icons/smartphone.svg" class="h-full mx-auto" alt=""></div></li>
    </ul>
    <div class="hidden md:block -mt-12 h-0.5 mx-32" style="background-color: #C3E4F7;"></div>
  </div>
  <ul class="cursor-default my-list mx-auto max-w-3xl">
    <li v-for="(item, index) in text" v-bind:key="index" @mouseover="overItem(index+1)" :class="highlight == index+1 ? 'active' : ''"><div>{{ index+1 }}</div> {{ item }}</li>
  </ul>
</template>

<script>

export default {
  name: 'Timeline',
  props: {
    headline: String,
    desc: String,
    text: [],
  },
  components: {
  },
  data: () => {
    return {
      highlight: 1,
    }
  },
  methods: {
    overItem(index) {
      this.highlight = index;
      console.log(this.text);
    }
  }
}
</script>

<style scoped>
p {
  margin: 1rem 0 0 0;
}
.my-list li {
  position: relative;
  padding: 8px 0;
  opacity: 0.5;
}
.my-list li div {
  display: inline-block;
  margin-right: 3.0rem;
}
.my-list li div:after {
  content: '';
  position: absolute;
  display: block;
  left: 22px;
  top: 23px;
  width: 25px;
  height: 2px;
  opacity: 0.7;
  background-color: #22F0E3;
}
.my-list li.active {
  opacity: 1;
}
</style>