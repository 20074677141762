<template>
  <MyHeader />
  <router-view />
  <MyFooter />
</template>

<script>
import MyHeader from './views/MyHeader.vue'
import MyFooter from './views/MyFooter.vue'

export default {
  name: 'App',
  components: {
    MyHeader,
    MyFooter
  }
}
</script>

<style scoped>
</style>