<template>
  <Headline :subtitle="'Unser Mantra: Fokus auf den User'" :seoStyle2="true" :useBorder2img="true">Nutzergetrieben</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <div class="lg:text-center">
        <p class="inline-block p max-w-2xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„When Apple came up with the Mac, IBM was spending at least 100 times more on R&D. Innovation is not about money. It's about the people you have, how you're led, and how much you get it.”</i> – Steve Jobs, Inventor</p>
      </div>
      <p>Vielleicht es ist Euch auch schon einmal passiert: Die neue Software ist fertig und nun bemerkt man, dass sie irgendwie <b>am User vorbeientwickelt</b> wurde. Der schwierigste Teil der Softwareenwicklung ist die <b>wirkliche</b> Konzentration auf die User-Anforderungen, weil sie sich schwer in den Entwicklungsprozess integrieren lassen. Agil und Scrum helfen wenig, wenn nicht regelmäßig User-Feedback schon während der Entwicklung in die nächsten Milestone integriert wird. Leider neigen viele dazu, schnell betriebsblind zu werden und sich zu sehr auf Strategien, Prozessen, Geschäftsmodelle, KPIs oder der ursprünglichen Idee zu konzentrieren.</p>
    </div>
    
    <Container class="my-16">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl flex flex-col md:flex-row">
        <div class="w-full md:w-8/12">
          <h2 class="h2">Bedarf herausfinden</h2>
          <p>Die Entscheidungen von Entscheidungsträger und Verantwortlichen stimmen nicht zwangsläufig mit denen der Nutzer überein. Folgender Lösungsansatz klingt einfach, erfordert jedoch Disziplin: Man sollte immer davon ausgehen, mit seinen Annahmen falsch zu liegen, um diese <b>durch Hypothesen zu validieren</b>.</p>
          <p>Es geht darum, herauszufinden, worauf die User <b>wirklich</b> Wert legen und Entscheidungen sollten stets durch Daten begründet werden. Denn nicht Nutzer sollten geschult werden müssen, sondern die Software sich denen anpassen.</p>
          <p>Nachfolgend unsere Methoden, wie wir vorgehen, um vor und während der Entwicklung näher an Eure Nutzer heranzukommen und Insights gewinnen zu können. Das dient dem Ziel, sich auf die <b>richtigen</b> Features zu konzentrieren und zu verhindern, unnötig Aufwand, Zeit und Geld zu investieren.</p>
        </div>
        <div class="w-1/2 md:w-4/12 mx-auto inline-flex order-first md:order-none">
          <img class="md:ml-auto" src="./../assets/img/howto/3.png" alt="User-getriebene Softwareentwicklung" />
        </div>
      </div>
    </Container>
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <h3 class="h3">Unsere User-Feedback Methoden</h3>
      <ol class="numberslist">
        <li>Entwerft klickbare Prototypen Eurer App/Website mit Mockup-Tools. Integriert nur die Kernfunktionen und sammelt Feedback von Arbeitskollegen und Freunden. Verbessert Eure Mockups in Iterationen. Jede Iteration wird Euch helfen, sich auf Funktionen zu fokussieren, die noch nicht gut kommuniziert oder nicht intuitiv zu bedienen sind. Außerdem wird es Euch aufzeigen, ob Ihr auf gewisse Funktionen gänzlich verzichten können. Es geht neben User Experience (UX) darum, die Prio für das wirklich Wichtige zu finden. Erst nachdem dieser Schnitt abgeschlossen ist, darf mit der Umsetzung durch Programmierung begonnen werden.</li>
        <li>Organisiert nach jedem (größeren) Milestones User-Acceptance-Tests, indem Ihr projekt-fremde Personen zu persönlichen Interviews einladen und Eure Software bedienen lassen, um Probleme von Usability, UX oder UI zu identifizieren. Schreibt Feedback auf und nehmt die Interaktionen als Screenrecording auf. Nehmt Euch am Ende jedes Interviews Zeit, mit Eurem Team das Verhalten des Users zu evaluieren. Wiederholt diesen Prozess regelmäßig mit Personen ein, die Eure Software noch nie benutzt haben.</li>
        <li>Nutzt Umfrage-Plattformen für A/B-Testing von Grafik-, Marketingmaterial und In-App (In-Web) Screenshots und fragt die Audience auch nach Verbesserungsvorschlägen. Vorteil dieser nicht-persönlichen Möglichkeiten ist, dass Ihr sehr einfach skalierbar sind (große Menge an Umfrage-Teilnehmern), spezifisch bestimmte Zielgruppen auswählen können und statistische Auswertungen erhalten.</li>
        <li>Führt einen Softlaunch Eurer App oder Website in bestimmten Testmärkten schon früh im Entwicklungsprozess durch, auch ohne ein fertiges Produkt zu haben. Lasst echte Kunden damit interagieren und es nutzen, um Insights zu erhalten, welche Funktionen welche Priorisierung und Ressourcen in Eurem noch anstehenden Entwicklungsprozess erhalten werden.</li>
        <li>Integriert Analytics-Tools in Eure Software und nehmt sich die Zeit, benutzerdefinierte Events zu definieren und einzubauen. Auf Eure Anwendung zugeschnittene Charts ermöglichen nun, Bottle-Necks und Churn-Points zu identifizieren. So kann es z.B. in einer App sein, dass Nutzer an einer bestimmten Stelle nicht weiter wissen oder bei einer Website bestimmte Bereiche häufig, Andere aber gar nicht anklicken.</li>
      </ol>
    </div>

    <Container class="my-8">
      <div class="container mx-auto max-w-screen-md px-4 lg:px-0">
        <div class="px-4 xl:-mx-32 2xl:-mx-64">
          <h3 class="h3">Softwareentwicklung Lebenszyklus</h3>
          <img src="./../assets/img/user_driven_timeline.png" alt="Benutzer-getriebene Softwareentwicklung" class="mt-8" />
        </div>
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <ol class="numberslist" start="6">
        <li>Nutzt A/B-Tests, um die Performance mehrerer Varianten per Analytics zu vergleichen. Testet nicht nur unterschiedliche Bilder, Texte und Layouts, sondern ganze Funktionen und den Einfluss dieser auf das User-Engagement.</li>
        <li>Legt Personas an und überprüfen diese regelmäßig, ob die reale Nutzer noch den Erwartungen an Eure Personas entspricht. Wenn sich Eure Zielgruppe unbewusst verändert, muss sich vielleicht auch Euer Produkt anpassen. Oder entscheidet Euch dazu, Änderungen zu machen, um die ursprüngliche Zielgruppe wieder verstärkt zu erreichen.</li>
        <li>Direktes Feedback: Habt ein offenes Ohr (und Auge) für App-Store Bewertungen und Kommentare sowie Feedback- und Support-E-Mails. Oftmals unterschätzt man, wie früh Nutzer bereits Usability-Probleme erkennen.</li>
        <li>Bietet einfache In-App-Feedback-Möglichkeiten wie ein Formular oder eine Sterne-Bewertung an und gebt Anreize (z.B. Incentives), damit Nutzer Feedback zu senden. Belohnt Nutzer und lasst sie wissen, dass Ihr deren Meinung ernst nehmen, indem Ihr antwortt und Ihr euren den Fortschritt (und Entscheidungen basierend darauf) zeigt.</li>
        <li>Stellt der Community Kanäle zur einfachen Kommunikation (wie Ticket-System, Forum, Blog oder Slack, Support-E-Mail) mit Eurer Firma zur Verfügung und pitchen sie hier Eure Ideen für kommende Features, um schon in frühen Entwicklungsphasen um Feedback zu bitten. Vielleicht bedarf es auch gar nicht ein Pitch Eurerseits, weil die Community bereits in kreativen Ideen austobt.</li>
        <li>Darf es ein bisschen experimenteller werden? Lasst Eure Early-Adopter zu einem Teil Ihres Teams werden. Laden sie in Euer Slack ein, gebt direkten Kontakt zu Eurem Projektmanager und bindet Ihr sie in den Entwicklungsprozess mit ein, während Ihr Euch ständig über den Fortschritt und die Priorisierung informieren. Versteckt die Kommunikation nicht, sondern teilt früh Infos und Priorisierung, um deren Motivation zu maximieren, Euch bestmöglich Feedback zu geben und euer Produkt zu testen. Auch wenn es im ersten Moment nach viel Aufwand klingt, wird deren langfristiges Engagement zu einem viel besseren Produkt führen.</li>
        <li>Macht Eure Software Open Source und fördert, dass technisch-versierte Nutzer an Eurem Projekt teilhaben und Feedback in Form von Bugfixes und neuen Funktionen direkt mit einbringen können.</li>
        <li>Stellt APIs für Eure Software bereit, damit andere Unternehmen und Entwickler Euer Ökosystem mit ihren eigenen Ideen erweitern können (wie die Entwickler-API von Facebook oder APIs für verschiedene große Plattformen wie Google Suite, Github oder Slack). Dadurch wird das eigene Produkt zu einer Plattform, welches von anderen Tools erweitert und um neue Funktionen bereichert werden kann.</li>
        <li>Wenn Euer Produkt ein Videospiel ist, fördert Modding, um das Ökosystem des Spiels zu erweitern. Unterstützt diese Entwickler, indem Ihr Tools, Level-Editoren und gute Dokumentationen bereitstellt. Auch eine Sandbox mit bestimmten Regeln kann entwickelt werden, in dem Eure Spieler Eure Kreativität ausleben können (z. B. Garry's Mod oder Minecraft).</li>
      </ol>
    </div>
    
    <Container class="my-8">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl flex flex-col md:flex-row">
        <div class="w-full md:w-8/12">
          <h3 class="h3 text-7xl mb-2 sm:mb-4">Fazit</h3>
          <p class="max-w-4xl">Wir entwickeln für Endnutzer, nicht für Pflichtenhefte. Es geht immer darum, Entscheidungs-Findung zu vereinfachen und gleichzeitig wirtschaftlich zu entscheiden! Dies gelingt am besten Daten-basiert. Wir fangen bei den Problemen der Benutzer an und arbeiten von dort aus rückwärts. Entscheidungen sollten nicht auf Gefühlen basieren, sondern ausschließlich auf User-Feedback und -Interaktionen.</p>
        </div>
        <div class="w-1/2 md:w-4/12 mx-auto inline-flex order-first md:order-none">
          <img class="md:ml-auto" src="./../assets/img/howto/6.png" alt="User-getriebene Softwareentwicklung" />
        </div>
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <p>PS: Als Grundlage und Selbstverständnis für alle vorgestellten Schritte sehen wir das Arbeiten mit agilen Vorgehensweisen und den Grundsätzen des <a href="https://agilemanifesto.org/">agile Manifesto</a>. Nur dies erlaubt Eurem Team, Priorisierungen während der laufenden Entwicklung zu ändern statt sich auf das Abarbeiten von Pflichtenhefte zu verlassen. Zeigt nach jedem Milestone den Fortschritt Euren Stakeholdern und weiht sie in den aktiven Entscheidungsprozess mit ein.</p>
    </div>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'

export default {
  name: 'UserDriven',
  components: {
    Headline,
    Container
  },
  methods: {
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
p {
  margin: 1rem 0 0 0;
}
p {
  margin: 1rem 0;
}
</style>