<template>
  <div class="relative" :style="'background-color: '+ (!!bgColor ? bgColor : '#FBFBFD')">
    <div v-if="!bgColor" class="absolute top-0 w-full h-24 opacity-90" style="background-image: linear-gradient(to bottom, #F3F4F6, #FBFBFD);"></div>
    <div v-if="!bgColor" class="absolute bottom-0 w-full h-24 opacity-90" style="background-image: linear-gradient(to top, #F3F4F6, #FBFBFD);"></div>
    <div :class="'container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8 z-10 relative ' + (styles ?? 'py-4 sm:py-16')">
      <slot>Content here</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    styles: String,
    bgColor: String,
  },
}
</script>

<style scoped>
</style>