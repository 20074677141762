<template>
  <Headline :subtitle="'Wir sind Techies im Herzen'" :seoStyle2="true" :useBorder2img="true">Das sind wir</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <WhoWeAre />
    <Technical />
    <Team />
    <div class="container max-w-screen-lg 2xl:max-w-screen-xl py-6 sm:py-0 pb-4 px-4 xl:px-8 text-center">
      <div class="mt-16 robots-nocontent" id="networkContainer">
        <div class="inline-block"><a href="https://www.digitalcluster.hamburg/" target="_blank"><img src="./../assets/img/logos/hhAtWork.webp" alt="Hamburg @ work" class="h-12 mx-4" /></a></div>
        <div class="inline-block"><a href="https://softwareallianz.de/" target="_blank"><img src="./../assets/img/logos/swad.webp" alt="Softwareallianz Deutschland" class="h-10 mx-4" /></a></div>
        <div class="inline-block"><a href="https://12min.me/" target="_blank"><img src="./../assets/img/logos/12minme.png" alt="12min.me" class="h-[2.75rem] ml-8" /></a></div>
      </div>
      <ContactInfos />
    </div>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import WhoWeAre from '../views/WhoWeAre.vue'
import Technical from './../views/Technical.vue'
import Team from './../views/Team.vue'
import ContactInfos from './../views/ContactInfos.vue'

export default {
  name: 'AboutUs',
  components: {
    Headline,
    WhoWeAre,
    Technical,
    Team,
    ContactInfos
  },
  methods: {
  }
}
</script>

<style scoped>
#networkContainer > * {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.2s;
}
@media (min-width: 640px) {
  #networkContainer > * {
    opacity: 0.7;
  }
  #networkContainer > *:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
    transform: scale(1.15);
  }
}
</style>