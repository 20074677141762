<template>
  <Headline :subtitle="'Leitprinzip der Softwerkstatt Firmenkultur'" :useBorder2img="true">New Work</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      
      <div class="lg:text-center">
        <p class="inline-block p max-w-3xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Every one of us has learned how to send emails on Sunday night. But how many of us know how to go watch a movie on Monday afternoon. You've unbalanced your life without balancing it with someone else.”</i> ― Ricardo Semler, brazilian businessman</p>
      </div>

    </div>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-16">

      <div class="grid grid-cols-2 md:grid-cols-3 gap-4 cursor-default" id="newWorkGrid">
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/10.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Flexibilität</h3>
          <p>Natürlich nutzen wir flexible Arbeitszeiten und Remote Work, was uns eine angenehme Work-Life-Balance ermöglicht. Dass wir unser Arbeitsleben nach unseren individuellen Bedürfnissen gestalten, macht uns alle produktiver, kreativer und zufriedener. Du willst moderne Arbeitskultur in einem Startup leben statt Regeln in einem Konzern? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/11.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Selbst<i class="sm:hidden">-<br /></i>bestimmung</h3>
          <p>Nimm einfach die Kreditkarte in die Hand und stelle deinen Arbeitsplatz so zusammen, wie Du ihn für effizienten Output benötigst. Ob Apple Vision Pro, Tickets für Konferenzen, einen höhenverstellbaren Schreibtisch oder die Kosten für AI-Tools: hier hast Du keine bürokratischen Hürden zu befürchten. Du weißt, was Du willst? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="hidden md:block rounded h-52 sm:h-64">
          <img src="./../assets/img/illu6.webp" class="-mt-8 lg:-mt-20 2xl:-mt-40 w-full rounded-xl mx-auto" alt="" />
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/17.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Tschüss, Hierarchien!</h3>
          <p>Wir wünschen uns von allen Mitarbeitenden, sich in kleine wie große Entscheidungen einzubringen. Diese Kultur der Mitbestimmung fördert nicht nur die Identifikation mit der Softwerkstatt, sondern trägt auch zu einer positiven, respektvollen Arbeitsumgebung bei. Du möchtest einen Job, bei dem du mehr als ein kleines Rädchen im System bist? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/14.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Zeit zum Lernen</h3>
          <p>Jeder zweite Freitag ist bei uns für private Projekte und Weiterbildungen reserviert. Dann bilden wir uns beispielsweise über einen Udemy-Kurs fort, coden an einem Herzensprojekt, schauen uns Youtube-Tutorials an oder, ganz verrückt, lesen ein Buch von Uncle Bob. Du bist wissbegierig und willst gefördert werden? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/15.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Kollaboration</h3>
          <p>Wir verstehen uns als Arbeitsgemeinschaft, die Kollaboration und Teamwork in den Fokus stellt. Jeder von uns bringt andere Stärken, fachlich wie persönlich, mit. Deshalb fördern wir durch Feedback & monatliche Retrospektiven den projektübergreifenden Wissensaustausch und nutzen Synergien unserer klugen Köpfe. Du willst Teil eines Teams von Experten werden? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="rounded h-52 sm:h-64">
          <img src="./../assets/img/illu7.webp" class="mt-4 sm:mt-0 max-h-52 sm:max-h-64 rounded-xl mx-auto" alt="" />
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/16.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Events und Hackathons</h3>
          <p>Regelmäßig besuchen wir Tech-Talks oder tüfteln gemeinsam an unkonventionellen Lösungen für Hackathons. Hier geht es nicht nur um Codes, sondern auch um den Teamspirit und einen kreativen Power-Up-Booster für den Arbeitsalltag. Du hast Lust auf fachliche Weiterbildung kombiniert mit positiven Vibes? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
        <div class="bg-gray-100 border border-gray-100 hover:bg-white rounded-xl hover:border-[#428BB0] hover:shadow-lg h-52 sm:h-64 p-2 sm:p-4 zoom">
          <img src="/icons/12.png" class="mx-auto h-24" alt="">
          <h3 class="h3">Innovation</h3>
          <p>Wir sind von Herzen Techies und lieben es, mit modernen Technologien wie Augmented Reality oder KI zu experimentieren. Klar, dass unser Everyday -Tech-Stack immer mit den besten Tools ausgestattet ist, die es auf dem Markt gibt. Du liebst es, Arbeitsprozesse effizient und interaktiv zu gestalten? <router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">Dann komm an Bord</router-link>.</p>
        </div>
      </div>

    </div>

    <Container class="mt-16 text-center">
      <p class="max-w-4xl mx-auto">Als junges Start-up sind flexible Arbeitsweisen seit dem ersten Tag in unserer DNA verankert. Wir tragen alle Entscheidungen und Firmenstrategien transparent ins Team und glauben daran, dass intrinsisches Arbeiten Hand in Hand mit wirtschaftlichem Denken einhergeht. Und mal ehrlich, wer arbeitet nicht am liebsten in einem motivierten Team, in dem jeder sein Potenzial entfalten kann?</p>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-16">

      <div class="text-center">
        <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Jobs entdecken</CtaButton>
      </div>
      <ContactInfos />
    </div>

  </div>
</template>

<script>
import CtaButton from '../views/CtaButton.vue'
import Headline from './../views/Headline.vue'
import ContactInfos from './../views/ContactInfos.vue'
import Container from './../views/Container.vue'

export default {
  name: 'NewWork',
  components: {
    CtaButton,
    Headline,
    ContactInfos,
    Container
  },
  data: () => {
    return {
    }
  },
  methods: {
    onClick() {
      this.$router.push({name: 'jobs'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>
#newWorkGrid {

}
#newWorkGrid > div {
  transition: all 0.4s;
}
#newWorkGrid > div.zoom:hover {
  transform: scale(1.35);
  z-index: 20;
}
#newWorkGrid > div > h3 {
  text-align: center;
}
#newWorkGrid > div:hover > h3 {
  line-height: 1.0em;
}
#newWorkGrid > div > p {
  transition: all 0.4s;
  opacity: 0;
  font-size: 0.9em;
  margin-top: -4rem;
  line-height: 1.25em;
}
#newWorkGrid > div.zoom:hover > p {
  opacity: 1;
  margin: -0.25rem 0 0 0;
}
#newWorkGrid > div.zoom > img {
  transition: all 0.4s;
  margin-top: 2rem;
}
#newWorkGrid > div.zoom:hover > img {
  margin-top: 0;
  margin-bottom: 0;
  height: 3rem;
}
@media (max-width: 1280px) {
  #newWorkGrid > div.zoom > p {
    line-height: 1.2em;
  }
}
@media (max-width: 768px) {
  #newWorkGrid > div:nth-child(1),
  #newWorkGrid > div:nth-child(4),
  #newWorkGrid > div:nth-child(6),
  #newWorkGrid > div:nth-child(8) {
    transform-origin: left !important;
  }
  #newWorkGrid > div:nth-child(2),
  #newWorkGrid > div:nth-child(5),
  #newWorkGrid > div:nth-child(9) {
    transform-origin: right !important;
  }
  #newWorkGrid > div.zoom:hover {
    transform: scale(1.8);
  }
  #newWorkGrid > div.zoom:hover > img {
    height: 1rem;
  }
  #newWorkGrid > div.zoom > p {
    font-size: 0.65rem;
    line-height: 1.25em;
  }
  #newWorkGrid > div.zoom > h3 {
    line-height: 1.0em;
  }
  #newWorkGrid > div.zoom:hover > h3 {
    margin-top: 0;
    font-size: 1.3rem;
  }
}
</style>