<template>
  <Headline :subtitle="'Privacy Policy'">Datenschutzerklärung</Headline>
  <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl pt-4 md:pt-8 px-4 lg:px-0 pb-32">
    <h2 class="h2">1. Datenschutzerklärung</h2>
    <h3 class="h3">1.1 Einleitung</h3>
    <p>Wir freuen uns über Euren Besuch auf unserer Website sowie über Euer Interesse an unserem Unternehmen. Auch damit Sie sich beim Besuch unserer Website sicher und wohl fühlen, nehmen wir den Schutz Eurer personenbezogenen Daten und deren vertrauliche Behandlung sehr ernst.</p>
    <p>Wir möchten Sie mit dieser Datenschutzerklärung darüber informieren, wann und zu welchen Zwecken wir personenbezogene Daten verarbeiten, die wir im Zusammenhang mit der Nutzung unserer Website erheben. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können.</p>
    <p>Selbstverständlich erfolgt die Verarbeitung Eurer personenbezogenen Daten unter Beachtung der Vorschriften der Europäischen Datenschutzgrundverordnung („DSGVO“), des Bundesdatenschutzgesetzes („BDSG“) sowie der sonstigen einschlägigen Datenschutzvorschriften.</p>
    <h3 class="h3">1.2 Datenerfassung auf unserer Website</h3>
    <p><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></p>
    <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</p>
    <p><strong>Wie erfassen wir Eure Daten?</strong></p>
    <p>Eure Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
    <p>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</p>
    <p><strong>Wofür nutzen wir Eure Daten?</strong></p>
    <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
    </p>
    <p><strong>Welche Rechte habt Ihr bezüglich Eurer Daten?</strong></p>
    <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Eurer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Euch ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
    <h3 class="h3">1.3 Analyse-Tools und Tools von Drittanbietern</h3>
    <p>Beim Besuch unserer Website kann Euer Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Euch zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern.</p>
    <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser Datenschutzerklärung informieren.</p>
    <h2 class="h2">2. Allgemeine Hinweise und Pflichtinformationen</h2>
    <h3 class="h3">2.1 Datenschutz</h3>
    <p>Die Betreiber dieser Seiten nehmen den Schutz Eurer persönlichen Daten sehr ernst. Wir behandeln Eure personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
    <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
    <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
    </p>
    <h3 class="h3">2.2 Hinweis zur verantwortlichen Stelle</h3>
    <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
    <ul style="list-style: circle; padding-left: 25px;">
      <li>Softwerkstatt GmbH</li>
      <li>Am Sandtorkai 77</li>
      <li>20457 Hamburg</li>
      <li>Telefon: 040 / 800 084 851</li>
      <li>E-Mail: moin@softwerkstatt.de</li>
    </ul>
    <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</p>
    <h3 class="h3">2.3 Widerruf Eurer Einwilligung zur Datenverarbeitung</h3>
    <p>Viele Datenverarbeitungsvorgänge sind nur mit Eurer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
    <h3 class="h3">2.4 Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
    <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_linksnode. html</a>.</p>
    <h3 class="h3">2.5 Recht auf Datenübertragbarkeit</h3>
    <p>Sie haben das Recht, Daten, die wir auf Grundlage Eurer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
    <h3 class="h3">2.6 Auskunft, Sperrung, Löschung</h3>
    <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Eure gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
    <h3 class="h3">2.7 Widerspruch gegen Werbe-Mails</h3>
    <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>
    <h2 class="h2">3. Datenerfassung auf unserer Website</h2>
    <h3 class="h3">3.1 Cookies</h3>
    <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Eurem Rechner keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf Eurem Rechner abgelegt werden und die Euer Browser speichert.</p>
    <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Eurem Endgerät gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es uns, Euren Browser beim nächsten Besuch wiederzuerkennen.</p>
    <p>Sie können Euren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.</p>
    <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Euch erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.</p>
    <h2 class="h2">4. Analyse Tools und Werbung</h2>
    <h3 class="h3">4.1 Google Analytics</h3>
    <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
    <p>Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Eurem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Eure Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.</p>
    <p>Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.</p>
    <p><strong>IP Anonymisierung</strong></p>
    <p>Wir haben auf dieser Website die Funktion IPAnonymisierung aktiviert. Dadurch wird Eure IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Eure Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Eurem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
    </p>
    <p><strong>Browser Plugin</strong></p>
    <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Eurer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Eure Nutzung der Website bezogenen Daten (inkl. Eurer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=de</a>.</p>
    <p><strong>Widerspruch gegen Datenerfassung</strong></p>
    <p>Sie können die Erfassung Eurer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Eurer Daten bei zukünftigen Besuchen dieser Website verhindert: <a href="javascript:gaOptout();">Google Analytics deaktivieren</a>.</p>
    <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von Google:
        <a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank">https://support.google.com/analytics/answer/6004245?hl=de</a>.</p>
    <p>AuftragsdatenverarbeitungWir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p> <p><strong>Demografische Merkmale bei Google Analytics</strong></p>
    <p>Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Eurem Google- Konto deaktivieren oder die Erfassung Eurer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.</p>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    Headline
  },
  methods: {
  }
}
</script>

<style scoped>
h2, h3 {
  margin-top: 1.0rem;
}
p, ul {
  margin: 1.0rem 0;
}
</style>