<template>
  <Curved class="gradient min-h-0">
    <div class="py-6 sm:py-0 pb-4 text-center overflow-hidden" id="services6">
      <div class="pre-headline color1">Digitalisierung Ahoi</div>
      <h2 class="h1">Als Flaggschiff an eurer Seite</h2>
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8 grid rid-cols-1 lg:grid-cols-2 gap-8 text-left text-gray-600 ">
      
        <article class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-6 transform transition-all scale-101 cursor-default">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/rakete.svg" alt="" class="w-8 inline-block ml-2 mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Change Management</h3>
          </div>
          <p>Unsere Change Manager kommen zum Einsatz, wenn es um komplexe Projekte geht, die vollumfänglich durchdacht und implementiert werden wollen. Das machen wir deshalb so erfolgreich, weil wir den Veränderungsprozessen mit optimistischer Attitüde und viel zwischenmenschlichem Feingefühl begegnen. Dass wir die fachliche Expertise wie Projektmanagement oder Qualitätssicherung mitbringen, versteht sich von selbst.</p>
          <router-link to="/change-management-hamburg" class="p"><span v-on:click="scrollToTop">Change Management mit Softwerkstatt ></span></router-link>
        </article>
        
        <article class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-6 transform transition-all scale-101 cursor-default">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/werkzeug.svg" alt="" class="w-8 inline-block ml-2 mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Softwareentwicklung</h3>
          </div>
          <p>Ihr habt bereits gut verzahnte Prozesse und seid beim Projektmanagement voll auf Kurs? Dann verstärken wir als Softwareentwickler Euer inhouse Techie-Team und fahren unter Eurer Flagge. Da wir Projektexpertise aus den unterschiedlichsten Unternehmen im Gepäck haben, bringen wir uns gerne mit Ideen und neuen Perspektiven ein. Als "externe Interne" haben wir auch ein geschultes Auge für das ein oder andere festgefahrene Rädchen im Maschinenraum.</p>
          <router-link to="/individualsoftware-hamburg" class="p"><span v-on:click="scrollToTop">Softwareentwicklung mit Softwerkstatt ></span></router-link>
        </article>
      </div>
      
    </div>
  </Curved>
</template>

<script>
import Curved from './Curved.vue'

export default {
  name: 'Services6',
  components: {
    Curved
  },
  data: () => {
    return {
      active: -1
    }
  },
  methods: {
    isDesktop() {
      return window.innerWidth >= 1280;
    },
    onMouseEnter(index) {
      this.active = index;
    },
    onMouseLeave() {
      this.active = -1;
    }
  }
}
</script>

<style scoped>
#services6,
#services6 h1,
#services6 h2,
#services6 h3 {
  color: #fff;
}
.gradient {
  background: linear-gradient(45deg, #56a5cd 0%, #67dfc4 90%) !important;
}
h3 {
  margin-bottom: 0;
}
article {
  box-shadow: 0 0 6px rgba(0,0,0,0.2);
  
}
article:hover {
  box-shadow: 0 10px 15px rgba(0,0,0,0.2);
}
article p {
  margin: 1rem 0 0 0;
}
</style>