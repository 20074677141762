<template>
  <div :class="(isOpen ? '' : 'opacity-0 pointer-events-none') + ' z-50 modal fixed w-full h-full top-0 left-0 flex items-center justify-center'">
    <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-70" @click="close"></div>
    <div class="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-40 overflow-y-auto">
      <div class="modal-content text-left">
        <div class="flex justify-between items-center pl-6 pr-4 pt-4">
          <p class="h2">{{headline}}</p>
        </div>
        <p class="px-6 pb-4">Wir können wir Euch weiterhelfen? Lasst es uns wissen. Ruft einfach durch.</p>
        <ul class="bg-gray-100 px-6 pt-4 pb-6">
          <li>Dario D. Müller</li>
          <li><a href="mailto:moin@softwerkstatt.de"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg> moin@softwerkstatt.de</a></li>
          <li><a href="tel:040800084851"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg> 040 / 800 084 851</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  data: () => {
    return {
      isOpen: false,
      headline: ''
    }
  },
  computed: {
    body: function () {
      return document.querySelector('body');
    },
    header: function () {
      return document.querySelector('header');
    }
  },
  methods: {
    open(value) {
      this.headline = value  != '' ? value : 'Kontakt aufnehmen'
      this.isOpen = true
      this.body.classList.add('overflow-hidden');
      this.body.classList.add('mr-[17px]');
      this.header.classList.add('w-[calc(100%-17px)]');
    },
    close() {
      this.isOpen = false;
      this.$emit('modalClosed', '')
      this.body.classList.remove('overflow-hidden');
      this.body.classList.remove('mr-[17px]');
      this.header.classList.remove('w-[calc(100%-17px)]');
    }
  }
}
</script>

<style scoped>
  .modal {
    transition: opacity 0.25s ease;
  }
  body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
  }
</style>