<template>
  <Headline :subtitle="'Talente finden und halten'">Arbeitsplatz der Zukunft</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <div class="lg:text-center">
        <p class="inline-block p max-w-xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Mehr als die Vergangenheit interessiert mich die Zukunft, denn in ihr gedenke ich zu leben.”</i> ― Albert Einstein, Physiker</p>
      </div>
      <div class="lg:flex">
        <div class="lg:w-7/12 2xl:pr-16 lg:mt-4 2xl:mt-8">
          <h3 class="h3 title">Zukunft gestalten</h3>
          <p class="my-4">Die Weichen für einen wettbewerbsfähigen “Arbeitsplatz der Zukunft” werden schon heute gestellt. Eine Arbeitsumgebung, die das Wohlbefinden der Mitarbeitenden in den Fokus rückt und gleichzeitig die Anforderungen an Flexibilität und Produktivität erfüllt, ermöglicht es, die Talente von morgen für sich zu gewinnen.</p>
          <p class="my-4">Gemeinsam befähigen wir Euer Unternehmen, hierbei ganz vorne mitzuspielen und Euch als "State of the Art" Arbeitgeber zu positionieren und durch individuell zugeschnittene Software und moderne Technologien zur Automatisierung das Potential Eures Teams voll zu nutzen.</p>
        </div>
        <div class="w-10/12 lg:w-5/12 2xl:px-8">
          <img src="./../assets/img/illu5.webp" class="rounded-xl" alt="" />
        </div>
      </div>
    </div>

    <Container class="mt-16">
      <h3 class="h1">Die Herausforderungen unserer Zeit</h3>
      <p class="w-full sm:max-w-5xl mx-auto text-left sm:text-center">Längst hat sich flächendeckend ein unumkehrbarer Wandel hin zur Remote-Arbeit vollzogen. Viele improvisierte Workflows sind jedoch seit der Pandemie im Modus der "Übergangslösung" verblieben, während gleichzeitig immer neue Standard-Softwarelösungen auf den Markt strömen.</p>
      <p class="w-full sm:max-w-5xl mx-auto text-left sm:text-center">Führungskräfte sind herausgefordert, effiziente Tools zu etablieren, die Teamarbeit produktiv zu gestalten sowie auf die individuellen Bedürfnisse der Mitarbeitenden einzugehen.</p>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 lg:mt-16 xl:mt-32">
      <h2 class="h1 mx-auto">So werdet Ihr zukunftsfähig</h2>
      <div class="grid grid-cols-2 md:grid-cols-3">
        <div class="p-4 border-r border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/3.webp" class="h-16 mb-4" alt="">
          <p>Ideen zur Förderung der individuellen Work-Life-Integration.</p>
        </div>
        <div class="p-4 md:border-r border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/19.webp" class="h-16 mb-4" alt="">
          <p>Digitalisierung von Dokumenten bis hin zum papierlosen Büro.</p>
        </div>
        <div class="p-4 border-r md:border-r-0 border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/16.webp" class="h-16 mb-4" alt="">
          <p>Schulungen für Mitarbeiter über neue Technologien.</p>
        </div>
        <div class="p-4 md:border-r border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/21.webp" class="h-16 mb-4" alt="">
          <p>Implementierung flexibler Arbeitszeit- und Arbeitsortmodelle.</p>
        </div>
        <div class="p-4 border-r border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/10.webp" class="h-16 mb-4" alt="">
          <p>Einrichtung digitaler Projektmanagement-Tools.</p>
        </div>
        <div class="p-4 border-b border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/18.webp" class="h-16 mb-4" alt="">
          <p>Einrichtung virtueller Meeting- und Konferenzräume.</p>
        </div>
        <div class="p-4 border-r border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/11.webp" class="h-16 mb-4" alt="">
          <p>Workshops für Führungskräfte, die den Wandel ins Team tragen.</p>
        </div>
        <div class="p-4 border-r border-gray-300 hover:bg-gray-100 cursor-default">
          <img src="/icons/1.webp" class="h-16 mb-4" alt="">
          <p>Entwicklung von Individualsoftware anstatt Standardsoftware.</p>
        </div>
        <div class="hidden md:block p-4 hover:bg-gray-100 cursor-default">
          <img src="/icons/20.webp" class="h-16 mb-4" alt="">
          <p>Bereitstellung mobiler Arbeitsgeräte und passender Software.</p>
        </div>
      </div>
    </div>

    <Container class="mt-16">
      <h3 class="h1">Fazit</h3>
      <p class="w-full sm:max-w-5xl mx-auto text-left sm:text-center">Wer die besten Talente für sich gewinnen möchte, muss sich jetzt auf die veränderten Arbeitsbedingungen einstellen. “Agil” und “flexibel” werden heutzutage nicht nur Projekte und Produktentwicklungen realisiert, sondern agil und flexibel wurde auch die Work-Life-Balance der Mitarbeitenden. In einer inspirierenden Arbeitsumgebung kann ein natürlicher Lern- und Wissenstransfer fließen. Dies geht Hand in Hand mit der Steigerung der Produktivität und Innovationskraft im Unternehmen.</p>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-16">

      <div class="text-center">
        <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Kontakt aufnehmen</CtaButton>
      </div>
      <ContactInfos />
    </div>


    
  </div>
</template>

<script>
import CtaButton from '../views/CtaButton.vue'
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'
import ContactInfos from './../views/ContactInfos.vue'

export default {
  name: 'ArbeitsplatzDerZukunft',
  components: {
    CtaButton,
    Headline,
    Container,
    ContactInfos,
  },
  data: () => {
    return {
      values: [{
        title: 'WAS lorem Ipsum..?',
        desc1: '1Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna al',
        desc2: ''
      }, {
        title: 'WIE',
        desc1: '2Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna al',
        desc2: ''
      }, {
        title: 'WARUM',
        desc1: '3Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna al',
        desc2: ''
      }]
    }
  },
  methods: {
    onClick() {
      this.$router.push({name: 'contact'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
</style>