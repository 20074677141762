<template>
  <Headline :subtitle="'Für glückliche Mitarbeiter und Kunden'" :seoStyle2="true" :useBorder2img="true">Unsere Prinzipien</Headline>
  <div class="absolute left-0 right-0 top-0 h-[23vw] overflow-hidden opacity-50">
    
    <img src="./../assets/img/anker.png" class="absolute left-[0%] top-64 transform scale-103 transition-all" alt="" />

    <img src="./../assets/img/anker.png" class="absolute -left-[2%] top-10 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute left-[8%] top-40 transform scale-103 transition-all" alt="" />
    
    <img src="./../assets/img/anker.png" class="absolute left-[6%] -top-12 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute left-[16%] top-12 transform scale-103 transition-all" alt="" />
    

    <img src="./../assets/img/anker.png" class="absolute -right-[2%] top-10 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute right-[8%] top-40 transform scale-103 transition-all" alt="" />
    
    <img src="./../assets/img/anker.png" class="absolute -right-[0.5%] top-64 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute right-[6%] -top-12 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute right-[16%] top-12 transform scale-103 transition-all" alt="" />
    <img src="./../assets/img/anker.png" class="absolute right-[24%] -top-12 transform scale-103 transition-all" alt="" />
    
  </div>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <div class="lg:text-center">
        <p class="inline-block p max-w-md pb-4 sm:pb-8 md:pb-16 mx-auto"><i>„Culture eats strategy for breakfast.”</i> ― Satya Nadella, CEO of Microsoft</p>
      </div>
    </div>
    
    <section>
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h2 class="h3">1.&nbsp;&nbsp;Nutzergetrieben</h2>
          <p>Wir digitalisieren Unternehmen – aber arbeiten für echte Menschen. Wir fangen bei der Anwenderproblematik an und arbeiten uns von dort aus rückwärts zur Lösung. Unsere Entscheidungen basieren nicht auf Gefühlen. Wir setzen auf regelmäßiges Testing und direktes User Feedback, um Software mit echtem Mehrwert auf den Markt zu bringen. <router-link to="/user-driven-development"><span v-on:click="scrollToTop">mehr</span></router-link></p>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/1.png" alt="Benutzer-getriebene Software-Entwicklung">
        </div>
      </div>
    </section>
    
    <Container :bgColor="'#F0F8FC'" :styles="'pt-16'">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-1/2 md:w-2/5 mr-32 inline-flex">
          <img class="object-contain" src="./../assets/img/howto/2.png" alt="Software Handwerkskunst">
        </div>
        <div class="w-full md:w-3/5 mt-6 mb-2">
          <h3 class="h3">2.&nbsp;&nbsp;Anspruchsvoll</h3>
          <p>Wir entwickeln nichts geringeres als Software nach den höchsten Maßstäben. Unsere Qualitätsstandards werden kontinuierlich an neue Entwicklungen und Technologien angepasst. Unser Commitment ist, durch Liebe zur Software Craftsmanship, hochwertiges Handwerk abzuliefern und die Messlatte höher zu setzen. Denn alle Ergebnisse, die unsere Werkstatt verlassen, sind mit Hinblick auf Langlebigkeit und Sicherheit entwickelt. <router-link to="/handwerk"><span v-on:click="scrollToTop">mehr</span></router-link></p>
        </div>
      </div>
    </Container>

    <section>
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h3 class="h3">3.&nbsp;&nbsp;Wissbegierig</h3>
          <p>Proaktives Lernen und die eigenen Kompetenzen zu erweitern ist fest in unserer Unternehmenskultur verankert. Deshalb ist beispielsweise ein Tag alle zwei Wochen ausschließlich für Wissensaustausch reserviert. Wir wollen, dass unsere Entwickler regelmäßig Neues lernen und sich durchgehend verbessern. Neuen Möglichkeiten begegnen wir neugierig, aufgeschlossen und erkunden sie stets. Unsere Kunden stimmen uns zu: Man sieht den Ergebnissen die Kultur an, in der sie entstanden sind.</p>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/3.png" alt="Lebenslanges Lernen bei Softwerkstatt">
        </div>
      </div>
    </section>

    <Container :bgColor="'#F0F8FC'" :styles="'pt-16'">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-1/2 md:w-2/5 mr-32 inline-flex">
          <img class="object-contain" src="./../assets/img/howto/4.png" alt="Freiräume und selbst Entscheidungen treffen">
        </div>
        <div class="w-full md:w-3/5">
          <h2 class="h3">4.&nbsp;&nbsp;Eigenständig</h2>
          <p>Wir wissen: Das beste Team erhält (und hält) man durch motivierende Freiheiten, nicht durch starre Vorgaben und Regeln. Wir arbeiten eigenständig und selbstbestimmt. Das setzt gegenseitiges Vertrauen und Respekt voraus. Selten hört man bei uns: „Das gehört nicht zu meinen Aufgaben.“ Noch vor dem Sprintende sind bereits alle Ziele erreicht? Glückwunsch, geh nach Hause und nimm Dir ein paar Tage frei. Maximale Agilität ist nicht nur in unserer Arbeitsweise verankert, sondern auch in der Unternehmenskultur.</p>
        </div>
      </div>
    </Container>

    <section class="pt-16">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h3 class="h3">5.&nbsp;&nbsp;Gleichgestellt</h3>
          <p>Bei uns sind alle aufgefordert, ihre Fähigkeiten und Talente einzubringen und die Entscheidungen der Anderen zu hinterfragen. Nicht der Lauteste, sondern das stärkste Argument gewinnt. Wir sind überzeugt: Die besten Lösungen lassen sich nur entwickeln, wenn jeder seine persönliche Erfahrung einbringt. Wir wollen auf lange Sicht entscheiden und opfern nicht langfristige Chancen zugunsten kurzfristiger Ergebnisse.</p>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/5.png" alt="Programmierung im Team">
        </div>
      </div>
    </section>

    <Container :bgColor="'#F0F8FC'" :styles="'pt-16'">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-1/2 md:w-2/5 mr-32 inline-flex">
          <img class="object-contain" src="./../assets/img/howto/6.png" alt="Freiräume und selbst Entscheidungen treffen">
        </div>
        <div class="w-full md:w-3/5 mt-16">
          <h3 class="h3">6.&nbsp;&nbsp;Transparent</h3>
          <p>In der Softwerkstatt setzen wir auf größtmöglichste Transparenz. Indem Umsätze, Businesspläne und Projektdaten für alle Mitarbeiter einsichtbar sind, fördern wir eine faires Arbeitsumfeld und pflegen ein wertschätzendes, respektvolles Miteinander.</p>
        </div>
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl 2xl:max-w-screen-xl px-4 lg:px-0 mt-16">
      <p>Diese Leitfäden dienen im Falle von Unstimmigkeiten dazu, Hilfestellung zu geben, um sich auf den Kern und die Grundwerte unserer Unternehmung berufen zu können. Dieses Vision soll dazu inspirieren, Offenheit und gemeinschaftliches Denken zu fördern. Falls Du etwas vermisst, kümmere dich schleunigst darum, dass es hier drin auftaucht. Und auch sonst ist Eigenbeteiligung und konstruktive Kritik erwünscht und willkommen.</p>
      <div class="text-center">
        <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Jobs entdecken</CtaButton>
      </div>
      <ContactInfos />
    </div>

  </div>
</template>

<script>
import CtaButton from '../views/CtaButton.vue'
import Headline from '../views/Headline.vue'
import Container from './../views/Container.vue'
import ContactInfos from './../views/ContactInfos.vue'

export default {
  name: 'Principles',
  components: {
    CtaButton,
    Headline,
    Container,
    ContactInfos
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    },
    onClick() {
      this.$router.push({name: 'jobs'})
      this.scrollToTop()
    }
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
p {
  margin: 0.5rem 0 0 0;
  line-height: 1.4em;
}
h2.h3, h3.h3 {
  font-weight: bold;
  color: #054374;
  font-size: 2.0em;
  margin: 0;
}
</style>