<template>
    <div class="container max-w-screen-lg xl:max-w-screen-xl sm:py-0 pb-2 px-4 md:px-8 2xl:px-0 text-center">
      <Slideshow :values="values" :largeHeadline="true" />
    </div>
</template>

<script>
import Slideshow from './Slideshow.vue'

export default {
  name: 'Services3',
  components: {
    Slideshow
  },
  data: () => {
    return {
      values: [{
        title: '<b>WAS</b> verändern?',
        desc1: 'Der Weg zur Digitalisierung: Geschäftsmodelle und Branchen verändern sich, Arbeitsabläufe werden effizienter. Als Softwareentwickler wissen wir aus der Praxis: Individuell zugeschnittene Software für Geschäftsprozesse bringen die effizientesten Strategien im Einklang mit Eurer Vision hervor.',
        desc2: ' Aber um als Unternehmen Marktvorsprünge auszubauen, braucht es vor allem das grundlegende Commitment zur Optimierung. Wir empfehlen, nicht das Rad neu zu erfinden, sondern auf fertige Lösungen oder Bausteine zu setzen, um bei 50&nbsp;% statt bei Null anzufangen. Das Ziel sollte zu jederzeit sein, neue Zielgruppen zu gewinnen oder Arbeitsabläufe zu verbessern, um die Produktivität Eurer Mitarbeiter, Kollegen und Kunden durch Individualsoftware nachhaltig zu steigern. Gleichzeitig werden wir alles geben, mit innovativer und strategischer Weitsicht Euch zu verändern.'
      }, {
        title: '<b>WIE</b> optimieren?',
        desc1: 'Ihr habt Optimierungspotenziale erkannt und Eure Anforderungen analysiert? Mit etablierten Vorgehensmodellen der agilen Softwareentwicklung, wie Scrum und Kanban, und Strategien wie Impact Mapping und Lean Startup kann Eure Software so entwickelt werden, dass Ihr flexibel auf Veränderungen reagieren könnt.  Erarbeitet zuerst einen passenden MVP, um Erkenntnisse zu sammeln und zu iterieren.',
        desc2: ' Wir glauben daran, dass Nutzerfeedback an erster Stelle stehen muss und messen daher unsere Ergebnisse vom ersten Prototypen an Usability und User Experience: Wir möchten Euch dabei unterstützen, gemeinsam über den Tellerrand zu schauen und diese neuen Blickwinkel einzunehmen. Unsere Learnings lassen wir mit einfließen, um den langfristigen Erfolg für Euer (und damit unser) Projekt zu gewährleisten, um das Arbeitsleben effizienter und produktiver zu machen.'
      }, {
        title: '<b>WARUM</b> das alles?',
        desc1: 'Technologien werden weiterhin die Arbeitswelt verändern und ein <a rel="nofollow" href="/arbeitsplatz-der-zukunft">Arbeitsplatz der Zukunft</a> Flexibilität und Produktivität steigern. Marc Andreessen prägte "Software Is Eating The World", ein Stichwort über ständige Veränderung, Optimierung und Automatisierung. Es ist Teil unserer Geschäftswelt geworden, denn Herausforderungen als Chancen zu sehen bietet viel Potenzial:',
        desc2: ' Digitalisierung ermöglicht, dauerhaft effektiver zu arbeiten, flexibel auf Marktveränderungen zu reagieren und von neuen Möglichkeiten frühzeitig zu profitieren. Denn Technologie ist die beste Schnittstelle zwischen Geschäftsmodellen, Mitarbeitern und Kunden. Hier kommen wir mit ins Boot: Wir möchten damit Euren Weg in eine digitalere Arbeitswelt verbessern und nachhaltig erleichtern.'
      }]
    }
  },
}
</script>

<style scoped>
.gradient {
  background: linear-gradient(45deg, #36c1c7 0%, #67dfc4 90%) !important;
}
</style>