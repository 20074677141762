<template>
  <Headline :subtitle="'Für Eure Digitalisierung'" :smallSizeMobile="true">Individualsoftware</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <div class="lg:text-center">
        <p class="inline-block p max-w-3xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Most good programmers do programming not because they expect to get paid or get adulation by the public, but because it is fun to program.”</i> ― Linus Torvalds, finnish developer</p>
      </div>
      <div class="lg:flex">
        <div class="lg:w-7/12 2xl:w-6/12 2xl:pr-16 lg:mt-8 2xl:mt-20">
          <h3 class="h3 title">Ausschließlich maßgefertigt</h3>
          <p class="text-2xl mt-6">Gemeinsam mit Euch entwickeln wir individuelle Software, die so einzigartig wie Euer Unternehmen selbst ist.</p>
          <ul class="checklist darkblue mb-4 2xl:mb-8" style="color: #214979;">
            <li>Genaue Bedarfsanalyse</li>
            <li>Unkomplizierte Anbinding an bestehende Systeme</li>
            <li>Höchste Sicherheit und langlebige Software</li>
          </ul>
          <CtaButton class="hidden sm:inline shadow-none special-button mx-auto" @clicked="onClick">Kontakt aufnehmen</CtaButton>
        </div>
        <div class="w-10/12 lg:w-5/12 2xl:w-6/12 2xl:px-8">
          <img src="./../assets/img/vorteile_softwaredev.png" alt="Maßgeschneiderte Software Vorteile: Anpassungen, Integration und Kosten/Nutzen" />
        </div>
      </div>
    </div>
  
    <Container :styles="'py-16 lg:flex 2xl:pb-0'">
      <div class="w-10/12 lg:w-5/12 2xl:w-6/12">
        <img class="w-8/12 ml-12 -mt-16" src="./../assets/img/softwareentwicklung_inituitive_funktionen.png" alt="Individuelle Softwareentwicklung mit inituitiver Funktionsweise">
      </div>
      <div class="lg:w-7/12 2xl:w-6/12 lg:pr-32">
        <h3 class="h3 title">Intuitive Funktionsweise</h3>
        <p class="text-2xl mt-6">Die Entwicklung eigener Anwenderprogramme macht Unternehmen wendiger für die Zukunft und kann den Vorsprung zum Wettbewerb vergrößern.</p>
        <ul class="list darkblue" style="color: #214979;">
          <li>Vom Prototyp bis zum komplexen System</li>
          <li>Nutzerfreundliche, intuitive Oberfläche</li>
          <li>Langfristig erweiterbare Möglichkeiten</li>
        </ul>
      </div>
    </Container>


    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <h2 class="h1 mt-8 lg:mt-16">Vorteile für Individualsoftware</h2>
      <div class="text-center">
        <p class="inline-block p max-w-3xl mx-auto">Eure Arbeitsabläufe sind kleinteilig, aufwendig und fressen Ressourcen? Programme wie Excel und Standardsoftware "von der Stange" können Eure Probleme nicht lösen?</p>
      </div>
      <!-- <p>Im Gegensatz dazu wird Standardsoftware, wie zum Beispiel Excel, für eine breite Zielgruppe entwickelt und sofort einsatzbereit. Die Funktionen sind für den Bedarf einer breiten Masse entwickelt. Spezielle Anforderungen, in einem sich stetig ändernden Markt, lassen sich damit selten oder nur sehr kostenintensiv abdecken.</p> -->
      
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8 mt-16">
        <div class="border border-gray-200 p-2 lg:p-6 lg:pt-8 shadow-sm rounded-md bg-white z-10">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/smartphone.svg" alt="" class="w-8 inline-block ml-2 mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Kosten/Nutzen</h3>
          </div>
          <p>Natürlich steht hinter maßgefertigten Produkten ein erhöhter Produktionsaufwand. So auch in der Softwareentwicklung: Zuerst analysieren wir Herausforderungen und wägen unterschiedliche Lösungswege ab. Danach geht es in die Programmierungs- und Testphase um abschließend die neue Software im Unternehmen zu integrieren.</p>
        </div>
        <div class="border border-gray-200 p-2 lg:p-6 lg:pt-8 shadow-sm rounded-md bg-white z-10">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/konzept.svg" alt="" class="w-12 inline-block mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Integration</h3>
          </div>
          <p>Die Softwareintegration in bestehende Systeme im Unternehmen ist unkompliziert. In wenigen Schritten lässt sie sich mit bestehenden CRM- oder Warenwirtschaftssystemen verbinden. Eine einmalige Einführung in das neue Anwenderprogramm durch unsere Experten ist meistens ausreichend: Denn nur intuitive und nutzerfreundliche Lösungen verlassen unsere Werkstatt.</p>
        </div>
        <div class="border border-gray-200 p-2 lg:p-6 lg:pt-8 shadow-sm rounded-md bg-white z-10">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/code.svg" alt="" class="w-12 inline-block mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Updates</h3>
          </div>
          <p>Für die eigene Software bestimmt natürlich Ihr selbst, wann der Funktionsumfang durch Updates erweitert werden soll. Einmalige Projektkosten sowie überschaubare Wartungs- und Supportkosten stehen monatlichen Lizenzkosten der Standardsoftware gegenüber. Die langfristige Anpassungsfähigkeit an Eure anderen Systeme bieten so nur in Eigenregie entwickelte Lösungen.</p>
        </div>
      </div>
    </div>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-8 xl:mt-16">
      <h2 class="h1">Und so machen wir das</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 text-white mt-8 lg:mt-24 mb-8 lg:mb-0">
        <div class="col-span-1 bg-gray-200 pb-6 sm:pb-8 md:pb-12 pt-2 sm:pt-8 md:pt-12 px-6 sm:px-8 md:px-16" style="background-color: #6ADBD2;">
          <h3 class="h3 text-white">Mit dem passenden Werkzeug</h3>
          <p>Für jedes Projekt wählen wir zunächst das passende Werkzeug. Unser breites Technologiespektrum umfasst Programmiersprachen, Plattformen, Touchpoints... Kurzgesagt: Wir können jede Idee in die Umsetzung bringen. Von der Modifikation bestehender Programme bis zur Entwicklung komplexer Individualsoftware.</p>
        </div>
        <div class="col-span-1 bg-gray-200 pb-6 sm:pb-8 md:pb-12 pt-2 sm:pt-8 md:pt-12 px-6 sm:px-8 md:px-16 lg:-mt-16 lg:mb-16" style="background-color: #417F99;">
          <h3 class="h3 text-white">Agil und wendig</h3>
          <p>Am liebsten arbeiten wir nach agilen Methoden wie Scrum oder Kanban. Dank dieser Modelle können wir die bestmögliche Qualität in der angestrebten Entwicklungszeit gewährleisten. Falls Euer Unternehmen noch nicht auf agile Arbeitsweisen setzt, führen wir die Zusammenarbeit auch im linearen Wasserfallmodell durch.</p>
        </div>
        <div class="col-span-1 bg-gray-200 pb-6 sm:pb-8 md:pb-12 pt-2 sm:pt-8 md:pt-12 px-6 sm:px-8 md:px-16 z-10" style="background-color: #15506F;">
          <h3 class="h3 text-white">Testen, Testen, Testen</h3>
          <p>Von Beginn an setzen wir auf bewährte Testmethoden und holen uns direktes User Feedback ein. So bringen wir clevere Software mit echtem Mehrwert für die hervor. Unser Anspruch ist es, Software zu entwickeln, die nicht nur das Pflichtenheft erfüllt, sondern auch Endanwender glücklich macht.</p>
        </div>
        <div class="col-span-1 bg-gray-200 pb-6 sm:pb-8 md:pb-12 pt-2 sm:pt-8 md:pt-12 px-6 sm:px-8 md:px-16 lg:-mt-16 lg:-mb-4 z-10" style="background-color: #36c1c7;">
          <h3 class="h3 text-white">Als Partner an Eurer Seite</h3>
          <p>Alle Produkte die unserer Werkstatt verlassen, sind auf Langlebigkeit und Sicherheit ausgerichtet. Aber unsere IT-Qualitätssicherung geht noch weiter: Wir haben auch immer die Einhaltung geplanter Ressourcen und die Risikominimierung während der Entwicklungphase im Blick. Nach erfolgreicher Integration stehen wir natürlich als Ansprechpartner weiterhin zur Seite.</p>
        </div>
      </div>
    </div>
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8 xl:mb-16">
      <ContactInfos />
    </div>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import ContactInfos from './../views/ContactInfos.vue'
import CtaButton from './../views/CtaButton.vue'
import Container from './../views/Container.vue'

export default {
  name: 'SoftwareDev',
  components: {
    ContactInfos,
    Headline,
    CtaButton,
    Container
  },
  methods: {
    onClick() {
      this.$router.push({name: 'contact'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
p {
  margin: 1rem 0 0 0;
}
</style>