<template>
  <Headline :subtitle="'Freelancer und externe Experten'" :seoStyle2="true">Contracting</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    
	
FREELANCER & EXPERTEN

Wir brennen drauf, die Frontends dieser Welt besser zu machen.

UNBEGRENZTES WISSEN FÜR Eure PROJEKTE

Die eigenen Herausforderungen sind greifbar, eine schnelle Lösung ist aber oft nicht in Sicht. Mit unseren Projektbeispielen geben wir Denkanstöße und zeigen mögliche Herangehensweisen.


EXPERTEN FÜR EXPERTEN
CONTRACTING



  </div>
</template>

<script>
import Headline from './../views/Headline.vue'

export default {
  name: 'Experten',
  components: {
    Headline
  },
  methods: {
  }
}
</script>

<style scoped>
</style>