<template>
  <div class="md:flex">
    <div class="md:w-1/2">
      <h3 class="headline text-4xl">Lust auf Neues?</h3>
      <div class="mr-4 2xl:mr-0 max-w-xl mt-2 lg:mt-12 lg:mt-4 sm:mb-6 lg:mb-12 text-2xl">
        <p class="mb-4">Wir suchen interessante Persönlichkeiten mit ausgeprägten Soft- und Kommunikations-Skills. Ausbildungswege und Lebensläufe interessieren uns weniger. </p>
      </div>
      <div class="hidden md:inline-block w-1/2 md:w-1/3 lg:w-1/2 pr-4 sm:pr-12 md:pr-8 lg:pr-16 2xl:pr-24 text-gray-700 mb-6">
        <div class="rounded-lg bg-white p-1"><img class="rounded-md" src="team/dario.webp" alt="Dario D. Müller | Softwerkstatt" /></div>
        <span>Dario Müller</span>
        <a href="https://www.linkedin.com/in/dariomueller/" target="_blank" class="inline-block h-8 w-8 align-top">
          <svg height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg" class="scale-[0.3] -translate-x-5 -translate-y-[1.4rem]"><g fill="none" fill-rule="evenodd"><path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/><path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/></g></svg>
        </a>
      </div>
    </div>

    <div class="md:w-1/2 rounded-md shadow-md bg-white p-4 align-top text-gray-700">
      <h2 class="h3 p-4 pb-0"><router-link to="/jobs" v-on:click="scrollToTop()" class="no-underline hover:underline">Jobs entdecken (2)</router-link></h2>
      <ul class="mb-4">
        <li class="p-4 pb-0 mt-[1px] hover:bg-gray-100 cursor-pointer rounded group transform transition-all scale-101" v-on:click="onClickjob1()">
          <h3 class="h3 group-hover:text-[#428BB0] hover-blue hover:underline inline-block">Senior Full-Stack TS/C# Entwickler</h3>
          <p class="mb-2">Wir suchen einen Frontend-Entwickler in Vollzeit. Du bist ein Allrounder und hast auch in deiner Freizeit schon Diverses programmiert?</p>
          <div class="border-b border-gray-300 pb-4 z-10 relative">
          </div>
        </li>
        <li class="p-4 pb-0 mt-[1px] hover:bg-gray-100 cursor-pointer rounded group transform transition-all scale-101" v-on:click="onClickJob2()">
          <h3 class="h3 group-hover:text-[#428BB0] hover-blue hover:underline inline-block">Senior C# .NET Backend Entwickler</h3>
          <p class="mb-2">Wir suchen einen oder mehrere Backend-Entwickler in Vollzeit als Externe Entwickler/Consultants. Dein Herz schlägt für Design Pattern & Clean Code, und du bist für Herausforderungen zu haben?</p>
          <div class="border-b border-gray-300 pb-4 z-10 relative">
          </div>
        </li>
      </ul>
      <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 float-right mr-4 mb-4" @clicked="onClick">Jobs entdecken</CtaButton>
      <div class="clear-both"></div>
    </div>
    
  </div>

</template>

<script>
import CtaButton from './../views/CtaButton.vue'

export default {
  name: 'HiringInfos',
  components: {
    CtaButton,
  },
  data: () => {
  },
  methods: {
    onClickjob1() {
      this.$router.push({name: 'fullstackdev'})
      this.scrollToTop()
    },
    onClickJob2() {
      this.$router.push({name: 'dotnetdev'})
      this.scrollToTop()
    },
    onClick() {
      this.$router.push({name: 'jobs'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
.hover-blue:hover {
  color: #0291D8;
}
</style>