<template>
  <Headline :subtitle="'Deine Expertise ist an Bord gefragt!'" :useBorder2img="false">Senior C# .NET Entwickler (m/w/d)</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <h2 class="h2">Zeit für sauberen Code!</h2>
      <p>Unsere <b>Softwerkstatt</b> ist ein junges Hamburger Start-Up, das sich auf die Digitalisierung mittelständischer Betriebe durch Programmierung interner Tools spezialisiert hat. Wir arbeiten als “Externe Interne” und Consultants projektbasiert mit spannenden Unternehmen zusammen, die uns ihre herausfordernden Digitalisierungsprojekte anvertrauen. ABWECHSLUNG ist unser USP und kein leeres Versprechen, denn mit der Softwerkstatt im Rücken wirst du alle 6-18 Monate an einem neuen, spannenden Projekt arbeiten.</p>
      <p>Du hast in der Backend-Entwicklung echt was auf dem Kasten und lernst nie aus? Als senioriger <b>C#/.NET Entwickler</b> programmierst du lupenreinen Code für unsere anspruchsvollen Kunden. Mit deinen Kommunikationsskills bereicherst du jedes Projektteam aufs Neue, aber genauso gerne nimmst du das Ruder selbst in die Hand und steuerst mit Präzision und Verantwortlichkeit das Projektziel an.</p>
      <img src="./../assets/img/jobs_1.png" alt="Was wir bieten">
    </div>
    
    <Container :styles="'py-4 sm:py-8'">
      <h2 class="h2">Deine Aufgaben:</h2>
      <ul class="list">
        <li>Du planst und konzipierst komplexe Systeme.</li>
        <li>Du übernimmst die C# Programmierung von modernen .NET Backends.</li>
        <li>Du entwirfst relationale Datenbanken und arbeitest mit Azure.</li>
        <li>Du verantwortest Problemanalysen und Fehlerbehebungen.</li>
        <li>Du evaluierst bestehende Software-Architektur unter Einsatz von Design Patterns und erhältst Code Reviews.</li>
      </ul>
      <div class="grid grid-cols-3 md:grid-cols-6 gap-4 text-center text-sm mt-8">
        <span><img src="./../assets/img/icons/icon_csharp.png" alt="C#" class="w-24 mx-auto" />C#</span>
        <span><img src="./../assets/img/icons/icon_dotnet.png" alt=".NET" class="w-24 mx-auto" />.NET</span>
        <span><img src="./../assets/img/icons/icon_resharper.png" alt="Resharper" class="w-24 mx-auto" />Resharper</span>
        <span><img src="./../assets/img/icons/icon_sqlserver.png" alt="SQL Server" class="w-24 mx-auto" />SQL Server</span>
        <span><img src="./../assets/img/icons/icon_git.png" alt="Git" class="w-24 mx-auto" />Git</span>
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <h2 class="h2">Deine Skills:</h2>
      <p>Wir suchen interessante Persönlichkeiten mit ausgeprägten Soft- und Kommunikationsskills, die unser familiäres Techie-Team bereichern.</p>
      <ul class="checklist">
        <li>Sehr gute Kenntnisse (5+ Jahre) in C# und .NET.</li>
        <li>Anwendung von Clean Code und gängigen Design Pattern, sowie Erfahrungen mit Unit- und Integrationstests.</li>
        <li>Gute Kenntnisse mit SQL Server.</li>
        <li>Grundlegende Kenntnisse rund um CI/CD und Azure.</li>
        <li>Grundlegende Kenntnisse im Umgang mit Web-Frontends.</li>
        <li>Eigenverantwortliches Handeln: „you build it, you run it!“.</li>
      </ul>
      <h2 class="h2 mt-8">Unsere Skills als Arbeitgeber:</h2>
    </div>

    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_school.png" alt="Weiterbildung" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Events, Hackathons &amp; ständig Neues lernen</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_fork.png" alt="Keine Hierarchien" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Keine Hierarchien, jeder ist Leader</span></div>
      </div>
    </div>
    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_clock.png" alt="Flexible Arbeitszeiten" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Flexible Arbeitszeiten und Arbeitsort</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_people.png" alt="Kleines Team" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Eigenverantwortung und Mitentscheiden</span></div>
      </div>
    </div>
    <div class="border-y-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_money.png" alt="Faires Gehalt" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Monetären peace of mind</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_company.png" alt="Direkte Einflussnahme auf Projekt und Firma" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Alle 6-18 Monate ein neues Projekt</span></div>
      </div>
    </div>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 my-8">
      <p>Wir verstehen uns als Zusammenschluss von Softwareentwicklern, die mit Spaß und Leidenschaft unsere Digitalisierung vorantreiben. Wir sind Techies im Herzen, aber auch Festivalgänger, Väter und Weltenbummler und nehmen deshalb unsere Arbeitsweise selbstbestimmt in die Hand.</p>
      <ul class="list mb-8">
        <li>Abwechslungsreiche Herausforderungen mit hohem Maß an Gestaltungsspielraum.</li>
        <li>Agiles und frustfreies Arbeiten mit Methoden wie Scrum/Kanban, Unit-Tests und einer offenen Fehlerkultur.</li>
        <li>Lernen und Wissensaustausch sind tief in unserer DNA verankert. So nutzen wir beispielsweise jeden zweiten Freitag für private Projekte und Weiterbildungen.</li>
        <li>Wir alle möchten eine ausgeglichene Work-Life-Balance und nutzen deshalb flexible Arbeitszeiten und Remote Work.</li>
        <li>In der Softwerkstatt ist jede:r Leader. Hierarchiegefälle und festgefahrene Strukturen wären unserem kreativen Tech-Flow nicht dienlich.</li>
        <li>Wir sind ein familiäres Team und haben eine ausgewogene Balance zwischen Eigenverantwortlichkeit und Teamspirit.</li>
        <li>Regelmäßig besuchen wir Tech-Talks oder tüfteln gemeinsam an unkonventionellen Lösungen für Hackathons.</li>
        <li>Respekt und Anerkennung spiegeln sich auch in unseren Gehältern wider. </li>
        <li>Arbeiten bei Softwerkstatt bedeutet, sich von starren Strukturen und ausgedienten Arbeitsmodellen zu verabschieden. Wir leben <router-link to="/new-work" v-on:click="scrollToTop()" class="no-underline hover:underline" style="color: #214979;">New Work</router-link>!</li>
      </ul>
      <h3 class="h3">Klingt nice?</h3>
      <p>Dann machen wir es doch nicht unnötig kompliziert. Sende uns einfach Deinen Lebenslauf ohne Anschreiben, und teile uns Deinen Eintrittstermin und Gehaltswunsch mit. Formalitäten sind uns weniger wichtig - lass uns einfach mal persönlich schnacken.</p>
      <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Segel setzen</CtaButton>
    </div>
  </div>
</template>

<script>
import CtaButton from './../views/CtaButton.vue'
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'

export default {
  name: 'JobDotNetDeveloper',
  components: {
    CtaButton,
    Headline,
    Container
  },
  methods: {
    onClick() {
      window.location = 'mailto:dario@softwerkstatt.de'
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>
h2, h3 {
  color: #0291D8;
}
p {
  margin: 1rem 0;
}
</style>