<template>
  <section :class="'bg-gray-200 relative ' + (fixed ? 'larger-height' : '')" :style="{ padding: (!skipBorderTop || usePaddingTop ? Math.floor(wantHeight / paddingDivider) : 0) + 'px 0 ' + (!skipBorderBottom ? Math.floor(wantHeight / paddingDivider) : 0) + 'px 0' }">
    <div v-if="!skipBorderTop" :class="'myborder myborder-top' + (borderTopGray ? ' gray' : '') + (useBorder2img ? ' img2' : '')" :style="{ height: wantHeight + 'px' }"></div>
    <div class="z-10" :class="fixed ? 'fixed bottom-0 w-full' : 'relative'">
      <slot>Content here</slot>
    </div>
    <div v-if="!skipBorderBottom" :class="'z-10 myborder myborder-bottom' + (borderBottomGray ? ' gray' : '') + (useBorder2img ? ' img2' : '')" :style="{ height: wantHeight + 'px' }"></div>
  </section>
</template>

<script>
export default {
  name: 'Curved',
  props: {
    skipBorderTop: Boolean,
    skipBorderBottom: Boolean,
    borderTopGray: Boolean,
    borderBottomGray: Boolean,
    fixed: Boolean,
    usePaddingTop: Boolean,
    useBorder2img: Boolean
  },
  data: () => {
    return {
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      aspectRatio: -1,
      wantHeight: 0,
      paddingDivider: 1
    }
  },
  methods: {
    onResize() {
      this.windowHeight = document.documentElement.clientHeight
      this.windowWidth = document.documentElement.clientWidth
      this.refresh()
    },
    refresh() {
      var borderWidth = 1920
      var borderHeight = 138
      var newAspectRatio = this.windowWidth / this.windowHeight
      if(this.aspectRatio != newAspectRatio) {
        this.aspectRatio = newAspectRatio
        var wantAspectRatio = borderWidth / borderHeight
        this.wantHeight = Math.floor(this.windowWidth / wantAspectRatio)
      }
    }
  },
  mounted() {
    this.refresh()
  },
  created() {
    window.addEventListener("resize", this.onResize)
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize)
  }
}
</script>

<style scoped>
.myborder {
  position: absolute;
  width: 100%;
  height: 300px;
  background: url('./../assets/img/border.png') no-repeat left center;
  background-size: cover;
}
.myborder.img2 {
  background-image: url('./../assets/img/border2.png');
}
.gray {
  background-image: url('./../assets/img/border_gray_200.png');
}
.myborder-top {
  top: 0;
}
.myborder-bottom {
  bottom: 0;
  -moz-transform: scaleX(-1) scaleY(-1) translateY(-1px);
  -o-transform: scaleX(-1) scaleY(-1) translateY(-1px);
  -ms-transform: scaleX(-1) scaleY(-1) translateY(-1px);
  -webkit-transform: scaleX(-1) scaleY(-1) translateY(-1px);
  transform: scaleX(-1) scaleY(-1) translateY(-1px);
}
.larger-height {
  height: 520px;
}
@media (max-width: 768px) {
  .larger-height {
    height: 470px;
  }
}
</style>