import { createWebHistory, createRouter } from 'vue-router'
import routes from './routes.js'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || ''
  document.querySelector('meta[name="description"]').setAttribute("content", to.meta.desc || '')
  next()
})

export default router