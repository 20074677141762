<template>
  <Headline :subtitle="'Privacy Policy'">Datenschutzerklärung</Headline>
  <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl pt-4 md:pt-8 px-4 lg:px-0 pb-32">
    <h2 class="h2">Privacy Policy MfG-App</h2>
    <p>Diese Datenschutzerklärung beschreibt, wie <b>Softwerkstatt GmbH</b> personenbezogene Daten der Nutzer der App <b>MfG</b> sammelt, verwendet, speichert und schützt. Der Schutz Ihrer Daten ist uns ein wichtiges Anliegen, und wir handeln im Einklang mit der Google Play User Data Policy sowie den anwendbaren Datenschutzgesetzen, einschließlich der DSGVO (Datenschutz-Grundverordnung).</p>

    <h3 class="h3">1. Verantwortlicher</h3>
    <p>Verantwortlich für die Datenverarbeitung ist:</p>
    <ul style="list-style: circle; padding-left: 25px;">
      <li>Softwerkstatt GmbH</li>
      <li>Am Sandtorkai 77</li>
      <li>20457 Hamburg</li>
      <li>Telefon: 040 / 800 084 851</li>
      <li>E-Mail: moin@softwerkstatt.de</li>
    </ul>

    <h3 class="h3">2. Welche Daten wir erheben</h3>
    <p><b>Personenbezogene Daten</b>: Wenn Sie die App "MfG" verwenden, können bestimmte persönliche Informationen erfasst werden, wie z. B. Nameund E-Mail-Adresse. Dies erfolgt ausschließlich mit Ihrer ausdrücklichen Zustimmung.</p>
    <p><b>Nutzungsdaten</b>: Die App sammelt Informationen darüber, wie Sie die App verwenden, z. B. welche Funktionen genutzt werden, Verbindungsdaten, Geräteinformationen (wie z. B. Gerätetyp, Betriebssystem) und IP-Adresse.</p>

    <h3 class="h3">3. Zweck der Datenerhebung</h3>
    <p>Die erhobenen Daten werden für folgende Zwecke verwendet:</p>

    <ul class="list">
      <li>Verbesserung der App-Funktionalität und Benutzererfahrung</li>
      <li>Bereitstellung von App-Updates und technischen Support</li>
      <li>Analytik zur Verbesserung des Services</li>
      <li>Sicherheit und Betrugserkennung</li>
    </ul>

    <h3 class="h3">4. Weitergabe und Übermittlung von Daten</h3>
    <p>Wir geben Ihre Daten nicht an Dritte weiter, es sei denn, es ist erforderlich, um die von Ihnen angeforderten Dienste bereitzustellen (z. B. Hosting-Dienstleister) oder wenn dies gesetzlich vorgeschrieben ist.</p>

    <h3 class="h3">5. Sicherheit Ihrer Daten</h3>
    <p>Softwerkstatt setzt technische und organisatorische Maßnahmen ein, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Verlust oder Missbrauch zu schützen. Dennoch kann keine Internetübertragung vollständig sicher sein.</p>

    <h3 class="h3">6. Speicherung und Verarbeitung der Daten</h3>
    <p>Ihre Daten werden ausschließlich in Deutschland gespeichert und verarbeitet. Es erfolgt keine Datenübertragung in Länder außerhalb der Europäischen Union. Softwerkstatt garantiert, dass alle Sicherheits- und Datenschutzanforderungen der DSGVO bei der Speicherung und Verarbeitung Ihrer Daten eingehalten werden.</p>

    <h3 class="h3">7. Rechte der Nutzer</h3>
    <p>Sie haben das Recht auf Zugang zu Ihren Daten, Berichtigung oder Löschung, Einschränkung der Verarbeitung sowie das Recht auf Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten. Wenn Sie diese Rechte ausüben möchten, können Sie uns unter der oben genannten Adresse kontaktieren.</p>

    <h3 class="h3">8. Änderungen der Datenschutzerklärung</h3>
    <p>Softwerkstatt behält sich das Recht vor, diese Datenschutzerklärung jederzeit zu ändern. Änderungen werden in der App veröffentlicht, und das Datum der letzten Aktualisierung wird unten angegeben.</p>

    <p>Letzte Aktualisierung: 16.10.2024</p>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'

export default {
  name: 'PrivacyPolicyMfg',
  components: {
    Headline
  },
  methods: {
  }
}
</script>

<style scoped>
h2, h3 {
  margin-top: 1.0rem;
}
p, ul {
  margin: 1.0rem 0;
}
</style>