<template>
  <section class="h-screen text-center sm:mt-16" id="welcome" :style="`height: ${currentHeight}px`">
    <h1 class="text-white text-4xl sm:text-3xl w-3/4 md:w-2/5 lg:w-2/5 mx-auto px-4" :style="`padding-top: ${textTop}px`">
      Softwerkstatt:
      <div class="hidden sm:inline"> Hamburgs Digitalisierungsexperten</div>
      <div class="inline sm:hidden"> Hamburgs Digitalisierungs- experten</div>
    </h1>
    <CtaButton class="mt-1 sm:mt-4 md:mt-8 2xl:mt-16 hidden sm:inline border-white bg-gray-500 bg-opacity-10 text-white hover-intro-gradient hover:text-white sm:mr-6 md:mr-0 mx-auto" @clicked="onClick">It’s a Match!</CtaButton>
  </section>
</template>

<script>
import CtaButton from './CtaButton.vue'

export default {
  name: 'Welcome',
  components: {
    CtaButton
  },
  data: () => {
    return {
      imgWidth: 3840,
      imgHeight: 3464,
      imgTextTop: 530,
      imgWidthMobile: 1200,
      imgHeightMobile: 1775,
      imgTextTopMobile: 230,
      currentHeight: 100,
      textTop: 100
    }
  },
  methods: {
    onResize() {
      this.refresh()
    },
    refresh() {
      var isDesktop = window.innerWidth > 768
      var width = document.documentElement.clientWidth

      if(isDesktop) {
        this.currentHeight = this.imgHeight * (width / this.imgWidth)
        this.textTop = this.imgTextTop * (width / this.imgWidth)
      } else {
        this.currentHeight = this.imgHeightMobile * (width / this.imgWidthMobile)
        this.textTop = this.imgTextTopMobile * (width / this.imgWidthMobile) - 5
      }
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
    onClick() {
      this.$router.push({name: 'itsamatch'})
      this.scrollToTop()
    }
  },
  mounted() {
    this.refresh()
  },
  created() {
    window.addEventListener('resize', this.onResize)
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize)
  }
}
</script>

<style scoped>
#welcome {
  background-image: url('./../assets/img/intro/island-3840px.jpg');
  background-size: cover;
}
@media (max-width: 1920px) {
  #welcome {
    background-image: url('./../assets/img/intro/island-1920px.jpg');
  }
}
@media (max-width: 1366px) {
  #welcome {
    background-image: url('./../assets/img/intro/island-1366px.jpg');
  }
}
@media (max-width: 768px) {
  #welcome {
    background-image: url('./../assets/img/intro/island-mobile-1200px.jpg');
  }
}
@media (min-width: 769px) {
  h1 {
    font-size: 3.4vw;
    line-height: 4vw;
  }
}
.hover-intro-gradient:hover {
  border-width: 0;
  background-image: linear-gradient(to right, #015086, #1D98B6);
  color: #fff;
}
</style>