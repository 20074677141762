<template>
  <Headline :subtitle="'Das Ökosystem als Antrieb'">Microsoft .NET</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      
      <div class="lg:text-center">
        <p class="inline-block p max-w-2xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>“Measuring programming progress by lines of code is like measuring aircraft building progress by weight.”</i> ― Bill Gates, dropout Harvard University</p>
      </div>

      <div class="lg:flex">
        <div class="lg:w-7/12 lg:pr-32 lg:mt-20">
          <h3 class="h3 title">Leistungsstarkes Werkzeug</h3>
          <p>Bei Softwerkstatt setzen wir auf .NET. Wir sind davon überzeugt, dass das Microsoft Ökosystem unseren Kunden und uns ermöglicht, stabile und langfristige Unternehmenssoftware zu entwickeln.</p>
          <ul class="checklist darkblue mb-4 2xl:mb-8" style="color: #214979;">
            <li>Erstklassige Sicherheit und Performance</li>
            <li>Stabile, über Jahre sich wenig verändernde Umgebung</li>
            <li>Integriert viele Funktionen zur Umsetzung von Backends</li>
          </ul>
        </div>
        <div class="lg:w-5/12">
          <img src="./../assets/img/microsoft-dotnet-oekosystem-csharp.webp" alt="" />
        </div>
      </div>
    </div>

    <Container>
      <h3 class="h1">Softwerkstatt <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="52" class="inline-block -mt-4 opacity-90 text-[#0291D8]"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg> .NET</h3>
      <p class="px-4 sm:px-0 mb-4 sm:mb-12 w-full md:max-w-4xl mx-auto text-left sm:text-center">.NET ist unser Allzweck-Werkzeug: In allen Phasen der Softwareentwicklung unterstützt uns das Microsoft Ökosystem beim Entwickeln von serverseitigen Anwendungen. Zudem steht per Azure Cloud ein skalierbares Hosting zur Verfügung.
      </p>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mt-16">
      <h2 class="h1">Unsere Tools</h2>
      <p>Wir bieten Euch erfahrene .NET Entwickler, welche handwerklich qualitativ hochwertige Programmierung in allen Entwicklungsphasen abdecken. Dabei erleichtert uns .NET die Digitalisierung von auf Unternehmensprozessen und -strukturen zugeschnittenen Softwarelösungen.</p>
      <div class="flex">
        <div class="w-2/3 z-10 mr-8 border border-gray-200 rounded-md shadow-sm bg-white">
          <img class="rounded-lg" src="./../assets/img/dotnet-oekosystem_plattformen-technologie.webp" alt="" />
        </div>
        <div class="w-1/3 border border-gray-200 rounded-md shadow-sm bg-white z-10 px-2 md:px-4 lg:px-6 pt-4 lg:pt-8 ">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/werkzeug.svg" alt="" class="w-12 inline-block mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Über .NET</h3>
          </div>
          <p>.NET (gesprochen: dotnet) ist eine Software-Plattform, die von Microsoft von Grund auf neu als Open Source entwickelt wurde. Es bietet unseren Softwareentwicklern ausgereifte Tools für die Individualentwicklung. Mit deren Hilfe entstehen schlanke und erweiterbare Webanwendungen, in denen viele Einsatzbereiche wie Web, Mobile bis Cloud in einer Plattform gebündert sind.</p>
        </div>
      </div>
    </div>

    <Container class="-mt-28 sm:-mt-28 mb-4 sm:mb-8 2xl:mb-16" :styles="'py-6 sm:pb-8 pt-28 sm:pt-32 md:pt-36 lg:pt-48 px-4 lg:px-0'">
      <h2 class="h1">Hier unterstützen wir</h2>
      <p class="max-w-5xl">Das Fachwissen unserer .NET Experten in der Microsoft-Technologien .NET hilft Euch, moderne und stabile Backend-Anwendungen zu entwickeln. Wir sichern eine hohe Softwarequalität durch umfangreiches Testing zu, um Ergebnisse messbar zu machen sowie setzen zur Steigerung der Programmierer-Effizienz auf die Nutzung von Frameworks Langfristige für bestmögliche Erweiterbarkeit und Wartung der Anwendungen.</p>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-16 text-left mb-8">
        <div class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-2 lg:p-6 lg:pt-8">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/konzept.svg" alt="" class="w-12 inline-block mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Unsere Leistungen</h3>
          </div>
          <ul class="list">
            <li>Unittests, Integrationstests, Netzwerktests, Pentests</li>
            <li>Verfügbarkeit an Entwicklern zum kurzfristigen, flexiblen Einsatz</li>
            <li>Hohe Technologiekompetenz durch jahrelange Erfahrungen</li>
            <li>Ausschließlich Inhouse Entwicklung in Hamburg</li>
          </ul>
        </div>
        <div class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-2 lg:p-6 lg:pt-8">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/code.svg" alt="" class="w-12 inline-block mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">Unsere Umsetzung</h3>
          </div>
          <ul class="list">
            <li>Umsetzung in C# mit .NET 8</li>
            <li>Aufsetzen und Überwachen des Azure Cloud Hosting</li>
            <li>Fehlerbehebungen und Performance-Optimierung</li>
            <li>Von der Konzeption bis zum Betrieb: Alles aus einer Hand</li>
          </ul>
        </div>
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <ContactInfos />
    </div>
    
  </div>
</template>

<script>
import Headline from '../views/Headline.vue'
import ContactInfos from './../views/ContactInfos.vue'
import Container from './../views/Container.vue'

export default {
  name: 'DotNet',
  components: {
    Headline,
    ContactInfos,
    Container
  },
  data: () => {
    return {
      active: 1,
      max: 6,
      highlight: -1
    }
  },
  methods: {
    clickedLeft() {
      this.active--;
      if(this.active == 0) {
        this.active = this.max;
      }
    },
    clickedRight() {
      this.active++;
      if(this.active > this.max) {
        this.active = 1;
      }
    },
    overItem(index) {
      this.highlight = index;
    },
    leaveItem() {
      this.highlight = -1;
    },
    onClickPrice() {
      this.$router.push({name: 'calculator'})
      this.scrollToTop()
    },
    onClickMeeting() {
      this.$router.push({name: 'contact'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
  }
}
</script>

<style scoped>
.custom-icon {
  width: 80px;
  max-width: 100%;
  min-width: 40px;
}
figure {
  display: none;
}
figure.active {
  display: block;
}
p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-list li div {
  font-weight: bold;
  display: inline;
}
.my-list li.active {
  font-weight: bold;
}
</style>