<template>
  <h2 class="pre-headline text-center mb-4">Unsere Prinzipien</h2>
  <Slideshow :values="values" :largeHeadline="true" />
</template>

<script>
import Slideshow from './Slideshow.vue'

export default {
  name: 'PrinciplesShort',
  components: {
    Slideshow
  },
  data: () => {
    return {
      values: [{
        title: 'Nutzergetrieben',
        desc1: 'Wir digitalisieren Unternehmen – aber arbeiten für echte Menschen. Wir fangen bei dem Anwender an und arbeiten uns von dort aus rückwärts zur Lösung. Unsere Entscheidungen basieren nicht auf Gefühlen. Wir setzen auf regelmäßiges Testing und direktes Feedback, um Software mit echtem Mehrwert auf den Markt zu bringen. Wir werden nie (mehr) am User vorbei entwickeln.<br /><br />',
        desc2: ''
      }, {
        title: 'Anspruchsvoll',
        desc1: 'Wir entwickeln nichts geringeres als Software nach den höchsten Maßstäben. Das Handwerk steht an erster Stelle, denn unser Commitment ist, durch Liebe zur Software Craftsmanship, die Messlatte für hochwertige Programmierung höher zu setzen. Denn alle Ergebnisse, die unsere Werkstatt verlassen, sind mit Hinblick auf Langlebigkeit und Sicherheit entwickelt.',
        desc2: ''
      }, {
        title: 'Wissbegierig',
        desc1: 'Proaktives Lernen und die eigenen Kompetenzen zu erweitern ist fest in unserer Unternehmenskultur verankert. Deshalb ist beispielsweise ein Tag alle zwei Wochen ausschließlich für Wissensaustausch reserviert. Wir wollen, dass unsere Entwickler regelmäßig Neues lernen und sich durchgehend verbessern. Neuen Möglichkeiten begegnen wir neugierig, aufgeschlossen und erkunden sie stets. Unsere Kunden stimmen uns zu: Man sieht den Ergebnissen die Kultur an, in der sie entstanden sind.',
        desc2: ''
      }, {
        title: 'Eigenständig',
        desc1: 'Wir wissen: Das beste Team erhält (und hält) man durch Freiheiten, nicht durch starre Regeln. Wir arbeiten eigenständig und selbstbestimmt. Das setzt gegenseitiges Vertrauen und Respekt voraus. Selten hört man bei uns: „Das gehört nicht zu meinen Aufgaben.“ Noch vor dem Sprintende sind bereits alle Ziele erreicht? Glückwunsch, geh nach Hause und nimm dir ein paar Tage frei. Maximale Agilität ist nicht nur in der Arbeitsweise verankert, sondern auch in unserer Unternehmenskultur.',
        desc2: ''
      }, {
        title: 'Gleichgestellt',
        desc1: 'Bei uns sind alle aufgefordert, ihre Fähigkeiten und Talente einzubringen und die Entscheidungen der Anderen zu hinterfragen. Nicht der Lauteste, sondern das stärkste Argument gewinnt. Wir sind überzeugt: Die besten Lösungen lassen sich nur entwickeln, wenn jeder seine persönliche Erfahrung einbringt. Wir wollen auf lange Sicht entscheiden und opfern nicht langfristige Chancen zugunsten kurzfristiger Ergebnisse.',
        desc2: ''
      }, {
        title: 'Transparent',
        desc1: 'In der Softwerkstatt setzen wir auf größtmöglichste Transparenz. Indem Umsätze, Strategien und Projektdaten für alle Mitarbeiter einsichtbar sind, fördern wir eine faires Arbeitsumfeld und pflegen ein wertschätzendes, respektvolles Miteinander.  Dies erhöht nicht nur die Motivation, sondern schafft so eine Arbeitsumgebung, wie wir alle bei unseren früheren Arbeitgebern vermisst haben. Diese Prinzipien sind nicht in Stein gemeißelt: Siehst Du eine Ergänzung, so sind es vielleicht bald Sieben.',
        desc2: ''
      }]
    }
  },
  methods: {
    onClick() {
      this.$router.push({name: 'principles'})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
h3 {
  font-size: 4.0rem;
}
</style>