<template>
  <section id="bgTechnical">
    <div class="container max-w-screen-lg 2xl:max-w-screen-xl py-6 sm:py-0 pb-4 px-4 xl:px-0 mb-8">
      
      <div class="lg:text-center">
        <p class="inline-block p max-w-xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Our prime purpose in this life is to help others.”.</i> ― Dalai Lama, spiritual leader of Tibet</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-12 mt-8">
        
        <div class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-6">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/smartphone.svg" alt="" class="h-10 inline-block ml-2 mr-6 -mt-4" />
            <h2 class="h3 inline-block" style="color: #214A7B;">Wir sind Entwickler, Erfinder...</h2>
          </div>
          <p>Made with <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" width="20" class="inline-block -mt-1" id="heart1"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
        </svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="-mt-1" width="20" id="heart2"><path fill-rule="evenodd" d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" clip-rule="evenodd" />
        </svg> in Hamburg. Wir sind ein Zusammenschluss leidenschaftlicher Softwarehandwerker. Wir digitalisieren mittelständische Unternehmen und entwickeln hochwertige Full-Stack und Backend Lösungen als Externe Entwickler und Consultants. Aber wir entwickeln nicht nur, wir erfinden...</p>
        </div>
        
        <div class="col-span-1 bg-white border border-gray-200 shadow-sm rounded-md p-6">
          <div class="-mx-6 px-6 pb-4 border-b-2" style="border-color: #428BB0">
            <img src="./../assets/img/icons/werkzeug.svg" alt="" class="h-8 inline-block ml-2 mr-6 -mt-4" />
            <h3 class="h3 inline-block" style="color: #214A7B;">...und Veränderungsbegleiter</h3>
          </div>
          <p>Wir tun das, was wir am liebsten machen: Software programmieren. Dabei stehen das <router-link to="/handwerk"><span v-on:click="scrollToTop">Handwerk</span></router-link> und der <router-link to="/user-driven-development"><span v-on:click="scrollToTop">Nutzer</span></router-link> stets im Vordergrund, Hierarchien und unnötige Prozesse im Hintergrund. Unser Commitment ist, durch Liebe zur Software Craftsmanship und agiler Vorgehensweise zu überzeugen und dabei langfristig Spaß zu haben.</p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'WhoWeAre',
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
h2 {
  margin-bottom: 0;
}
#heart2 {
  display: none;
}
#about-us-text:hover #heart1 {
  display: none;
}
#about-us-text:hover #heart2 {
  display: inline-block;
  color: #046695;
}
.square {
  margin-top: 100%;
  position: relative;
}
.square img {
  margin-top: -100%;
}
.square span {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
}
h3 {
  margin: 0;
}
p {
  margin: 1rem 0 0 0;
}
</style>