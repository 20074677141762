<template>
  <div class="lg:w-1/2 inline-block">
    <h3 class="headline text-4xl text-white">Kurs auf Digital</h3>
    <p class="mr-4 2xl:mr-0 max-w-xl mt-12 lg:mt-4 mb-8 lg:mb-16 text-2xl">Erzählt uns von Euren Herausforderungen. Von Hamburg aus navigieren wir Euer Projekt. Lasst gerne einmal schnacken.</p>
    <div class="inline-block w-1/2 md:w-1/3 lg:w-1/2 pr-4 sm:pr-12 md:pr-8 lg:pr-16 2xl:pr-24 text-gray-700 mb-6">
      <div class="rounded-lg bg-white p-1"><img class="rounded-md" src="team/dario.webp" alt="Dario D. Müller | Softwerkstatt" /></div>
      <span>Dario Müller</span>
      <a href="https://www.linkedin.com/in/dariomueller/" target="_blank" class="inline-block h-8 w-8 align-top">
        <svg height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg" class="scale-[0.3] -translate-x-5 -translate-y-[1.4rem]"><g fill="none" fill-rule="evenodd"><path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/><path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/></g></svg>
      </a>
    </div>
    <div class="inline-block w-1/2 md:w-1/3 lg:w-1/2 pr-4 sm:pr-12 md:pr-8 lg:pr-16 2xl:pr-24 text-gray-700">
      <div class="rounded-lg bg-white p-1"><img class="rounded-md" src="team/renke.webp" alt="Renke Haverich | Softwerkstatt" /></div>
      <span>Renke Haverich</span>
      <a href="https://www.linkedin.com/in/renke-haverich-815ab470/" target="_blank" class="inline-block h-8 w-8 align-top">
        <svg height="72" viewBox="0 0 72 72" width="72" xmlns="http://www.w3.org/2000/svg" class="scale-[0.3] -translate-x-5 -translate-y-[1.4rem]"><g fill="none" fill-rule="evenodd"><path d="M8,72 L64,72 C68.418278,72 72,68.418278 72,64 L72,8 C72,3.581722 68.418278,-8.11624501e-16 64,0 L8,0 C3.581722,8.11624501e-16 -5.41083001e-16,3.581722 0,8 L0,64 C5.41083001e-16,68.418278 3.581722,72 8,72 Z" fill="#007EBB"/><path d="M62,62 L51.315625,62 L51.315625,43.8021149 C51.315625,38.8127542 49.4197917,36.0245323 45.4707031,36.0245323 C41.1746094,36.0245323 38.9300781,38.9261103 38.9300781,43.8021149 L38.9300781,62 L28.6333333,62 L28.6333333,27.3333333 L38.9300781,27.3333333 L38.9300781,32.0029283 C38.9300781,32.0029283 42.0260417,26.2742151 49.3825521,26.2742151 C56.7356771,26.2742151 62,30.7644705 62,40.051212 L62,62 Z M16.349349,22.7940133 C12.8420573,22.7940133 10,19.9296567 10,16.3970067 C10,12.8643566 12.8420573,10 16.349349,10 C19.8566406,10 22.6970052,12.8643566 22.6970052,16.3970067 C22.6970052,19.9296567 19.8566406,22.7940133 16.349349,22.7940133 Z M11.0325521,62 L21.769401,62 L21.769401,27.3333333 L11.0325521,27.3333333 L11.0325521,62 Z" fill="#FFF"/></g></svg>
      </a>
    </div>
  </div>

  <div class="hidden sm:inline-block lg:w-1/2 rounded-md shadow-2md bg-white p-8 align-top text-gray-700">

    <div class="grid grid-cols-12 gap-6">
      <h2 class="h3 col-span-12">Wer schreibt, der bleibt</h2>
      <div class="col-span-12 sm:col-span-12">
        <!-- <label for="name" class="block text-gray-700">Name</label> -->
        <input type="text" name="name" placeholder="Name" id="name" v-model="name" class="border-2 px-4 py-2 mt-1 border-gray-300 block w-full shadow-sm rounded-md focus:ring-2">
      </div>
      
      <div class="col-span-12 sm:col-span-6">
        <!-- <label for="email" class="block text-gray-700">E-Mail</label> -->
        <input type="text" name="email" placeholder="E-Mail" id="email" v-model="email" class="border-2 px-4 py-2 mt-1 border-gray-300 block w-full shadow-sm rounded-md focus:ring-2">
      </div>
      
      <div class="col-span-12 sm:col-span-6">
        <input type="text" name="phone" placeholder="Telefon" id="phone" v-model="phone" class="border-2 px-4 py-2 mt-1 border-gray-300 block w-full shadow-sm rounded-md focus:ring-2">
      </div>

      <div class="col-span-12">
        <div class="mt-1">
          <textarea id="desc" name="desc" rows="3" v-model="desc" class="border-2 px-4 py-2 mt-1 border-gray-300 block w-full shadow-sm rounded-md focus:ring-2" placeholder="Nachricht"></textarea>
        </div>
        <p class="mt-2 text-sm text-gray-500"></p>
      </div>

    </div>
    <div class="text-center mt-8">
      <p v-if="isError" class="text-red-600">Leider ist ein Fehler aufgetreten.</p>
      <p v-if="isLoading">Lädt...</p>
    </div>

    <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 float-right" @clicked="sendForm">Senden</CtaButton>
    <div class="clear-both"></div>
  </div>

</template>

<script>
import CtaButton from './../views/CtaButton.vue'

export default {
  name: 'ContactForm',
  components: {
    CtaButton,
  },
  data: () => {
  },
}
</script>

<style scoped>
</style>