<template>
  <Container>
    <h2 class="pre-headline text-center">Unser Tech Stack</h2>
    <h3 class="h1">Maschinenraum</h3>
    <p class="w-full sm:max-w-4xl mx-auto text-left sm:text-center">Für jedes neue Projekt wählen wir zunächst das passende Werkzeug aus unserem breit aufgestellten Tech Stack. Wir entwickeln Individualsoftware mit Microsoft-Technologie und sind auf .NET spezialisiert. Hierbei haben wir Spaß:</p>
    <div class="max-w-4xl mx-auto mt-8">
      <img src="./../assets/img/tech_stack_7.webp" alt="Software Tech Stack und Programmiersprachen">
    </div>
  </Container>
</template>

<script>
import Container from './../views/Container.vue'

export default {
  name: 'Technical',
  components: {
    Container
  },
  data: () => {
    return {
      isMore: false
    }
  },
  methods: {
    onClickCta() {
      this.$emit('clicked', 'Werkstatt betreten')
    },
    toggleMore(e) {
      this.isMore = !this.isMore
      e.preventDefault()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
.gradient {
  background: linear-gradient(45deg, #36c1c7 0%, #67dfc4 90%) !important;
}
li span {
  color: rgba(156, 163, 175, 1);
}
</style>