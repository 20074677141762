<template>
  <Headline :subtitle="'Recruiting, Business Development, Operations'">Werkstudium</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0 mb-8">
      <p>Du interessierst Dich für die Softwareentwicklungs-Branche, bist offen, kontaktfreudig, engagiert, freundlich, kannst Dich gut in andere Menschen hineinversetzen und lässt Dich von einem Nein nicht demotivieren? Bei uns kannst Du Deine ersten Erfahrungen in den Bereichen Recruiting, Business Development und Operations sammeln oder ausbauen und schnell Verantwortung übernehmen.</p>
      <p>Softwerkstatt entwickelt als Dienstleister Individualsoftware und verstärkt mit externen Entwicklern Unternehmen bei ihren internen Digitalisierungsprojekten. Um hierbei weiter Fahrt aufzunehmen, benötigen wir Verstärkung.</p>
      <img src="./../assets/img/jobs_3.png" alt="Was wir bieten">
    </div>
    
    <Curved>
      <div class="container mx-auto max-w-screen-lg md:pt-8 px-4 lg:px-0">
        <h2 class="h2" style="color: #5DCAC7;">Deine Aufgaben:</h2>
        <ul class="list">
          <li>Planung und Durchführung von Recruiting Maßnahmen</li>
          <li>Recherche potenziell interessanter Kooperationspartner</li>
          <li>Unterstützung im Vertrieb und Betreuung von Kunden und Dienstleistern</li>
          <li>Unterstützung im operativen Tagesgeschäft</li>
          <li>Erstellung von Angeboten und Präsentationen</li>
        </ul>
      </div>
    </Curved>

    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0">
      <h2 class="h2" style="color: #5DCAC7;">Das solltest Du mitbringen:</h2>
      <ul class="checklist">
        <li>Du denkst mit, und machst nicht nur etwas, weil es dir gesagt wurde</li>
        <li>Du bist gut darin, Aufgaben nach Sinnhaftigkeit zu hinterfragen</li>
        <li>Bereitschaft und Fähigkeit mit Kunden und Dritten zu telefonieren</li>
        <li>Strukturierte Arbeitsweise und rationales, logisches denken</li>
        <li>Freundlichkeit, Engagement und Zuverlässigkeit</li>
        <li>Eigenverantwortliches Handeln: „you build it, you run it!“</li>
      </ul>
      <h2 class="h2 mt-16" style="color: #5DCAC7;">Das bieten wir Dir:</h2>
    </div>

    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_school.png" alt="Weiterbildung" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Regelmäßig neues lernen</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_fork.png" alt="Keine Hierarchien" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Keine Hierarchien</span></div>
      </div>
    </div>
    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_clock.png" alt="Flexible Arbeitszeiten" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Flexible Arbeitszeiten (ca. 20h/Woche)</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_people.png" alt="Kleines Team" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Kleines Team und Eigenverantwortung</span></div>
      </div>
    </div>
    <div class="border-t-2 border-b-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_money.png" alt="Faires Gehalt" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Stundenlohn: 15 €/h</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_company.png" alt="Direkte Einflussnahme auf Projekt und Firma" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Einflussnahme und Eigenständigkeit</span></div>
      </div>
    </div>

    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0 my-8">
      <h3 class="h3" style="color: #5DCAC7;">Hört sich gut an?</h3>
      <p>Lass Anschreiben und Lebenslauf weg. Sende uns ein kurzen Text oder Video, in dem Du beschreibst, wieso Du für die o.g. Tätigkeiten brennst. Bis bald!</p>
      <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Leinen los</CtaButton>
    </div>
  </div>
</template>

<script>
import CtaButton from './../views/CtaButton.vue'
import Curved from './../views/Curved.vue'
import Headline from './../views/Headline.vue'

export default {
  name: 'Werkstudent',
  components: {
    CtaButton,
    Headline,
    Curved
  },
  methods: {
    onClick() {
      window.location = 'mailto:dario@softwerkstatt.de'
    }
  }
}
</script>

<style scoped>
p {
  margin: 1rem 0;
}
</style>