<template>
  <Headline :subtitle="'Komm an Bord'" :useBorder2img="true">Jobs entdecken</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      
      <div class="lg:text-center">
        <p class="inline-block p max-w-md pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„I hope you know what you’re doing.”</i> ― Leia Organa, General of Imperial Senate, Princess of Alderaan</p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <img src="./../assets/img/aboutus/foto1.jpg" alt="Unkompliziertes Team" title="Unkompliziertes Team" class="col-span-1 shadow-sm rounded-md" />
        <img src="./../assets/img/aboutus/foto2.jpg" alt="Cooles Büro" title="Cooles Büro" class="col-span-1 shadow-sm rounded-md" />
        <img src="./../assets/img/aboutus/foto3.jpg" alt="Einblick in Kalle's Arbeitsweise" title="Einblick in Kalle's Arbeitsweise" class="hidden md:block col-span-1 shadow-sm rounded-md" />
      </div>

      <p class="max-w-5xl">Wir suchen interessante Persönlichkeiten mit technischem Know-How und ausgeprägten Soft- und Kommunikations-Skills. Ausbildungswege und Lebensläufe interessieren uns weniger.
        Mit Softwerkstatt wollen wir unser gemeinsames Abenteuer zur besten Zeit unseres Lebens machen.
        <span class="text-3xl absolute -mt-1">☺︎</span>
      </p>
      <p class="mb-4 max-w-3xl">Du willst uns einfach erstmal kennenlernen? Sag Bescheid und komm einen Tag vorbei, unverbindlich reinzuschnuppern und uns kennenzulernen.</p>
    </div>

    <Container>
      <div class="sm:grid sm:grid-cols-2 gap-x-8 max-w-3xl mx-auto">
        <h2 class="h1 col-span-2">Jobs to be done</h2>
        <div class="border-2 h-52 sm:h-64 mb-4 relative rounded-md shadow hover:shadow-md z-10 border-[#428BB0] bg-white transform transition-all scale-103 cursor-pointer" v-for="job in jobs" :key="job.title" v-on:click="onClickJob(job.routeName)">
          <div class="h-24 bg-gray-100 absolute left-0 right-0 top-0 border-b border-gray-300 rounded-t-md"></div>
          <div class="inline-block relative">
            <img src="./../assets/img/icons/icon_dotnet.png" :alt="job.title" class="w-20 pl-4 pt-4" v-if="job.title.indexOf('C#') !== -1" />
            <img src="./../assets/img/icons/icon_school.png" :alt="job.title" class="w-20 pl-4 pt-4" v-else-if="job.title.indexOf('student') !== -1" />
            <img src="./../assets/img/icons/icon_fork.png" :alt="job.title" class="w-20 pl-4 pt-4" v-else />
          </div>
          <span class="absolute left-20 top-8 inline-block text-2xl pl-2">{{job.title}}</span>
          <ul class="absolute left-0 right-0 bottom-0 p-4">
            <li><img src="./../assets/img/icons/icon_clock2.png" alt="" class="w-6 opacity-70 inline-block pr-2" />{{job.location}}</li>
            <li><img src="./../assets/img/icons/icon_calender.png" alt="" class="w-6 opacity-70 inline-block pr-2" />{{job.when}}</li>
            <li><img src="./../assets/img/icons/icon_location2.png" alt="" class="w-6 opacity-70 inline-block pr-2" />{{job.where}}</li>
          </ul>
        </div>
      </div>
    </Container>
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <PrinciplesShort class="-mt-28 sm:-mt-28 mb-4 sm:mb-8 2xl:mb-16" :styles="'py-6 sm:pb-8 pt-28 sm:pt-32 md:pt-36 lg:pt-40 px-4 lg:px-0'" />
    </div>

    <Container>
      <div class="px-4 sm:px-0">
        <div class="sm:float-left sm:w-1/2">
          <h3 class="h3" style="color: #428BB0;">Ahoi, ich bin Dario</h3>
          <p>Du siehst dich als Experte in Deinem Fachgebiet? Lass uns zusammenarbeiten. Hast Du Fragen an mich oder eine Bewerbung im Gepäck? Lass das Anschreiben gerne weg und schick mir eine Mail.</p>
          <p><a href="mailto:dario@softwerkstatt.de">dario@softwerkstatt.de</a></p>
        </div>
        <img class="sm:float-left w-2/5 2xl:w-1/4 border-4 rounded-full mx-auto xl:ml-16" style="border-color: #3BC4C6;" src="team/dario.webp" alt="Dario D. Müller | Softwerkstatt" />
        <div class="clear-both"></div>
      </div>
    </Container>
  
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <h3 class="h1">Über Softwerkstatt</h3>
      <div class="grid grid-cols-1 sm:grid-cols-3 gap-8 mt-8">
        <div class="bg-gray-50 border border-gray-300 h-76 relative rounded-md shadow-sm text-center pt-24 z-10">
          <p>Gegründet:<br /><span class="text-5xl">2021</span></p>
        </div>
        <img src="./../assets/img/aboutus/foto5.jpg" alt="Ausblick aus dem Büro" title="Ausblick aus dem Büro" class="col-span-1 shadow-sm rounded-md z-10" />
        <div class="bg-gray-50 border border-gray-300 h-76 relative rounded-md shadow-sm text-center pt-24 z-10">
          <p>Ø Berufserfahrung:<br /><span class="text-5xl">11 Jahre</span></p>
        </div>
        <img src="./../assets/img/aboutus/foto4.jpg" alt="Cooles Technik" title="Cooles Technik" class="col-span-1 shadow-sm rounded-md z-10" />
        <div class="bg-gray-50 border border-gray-300 h-76 relative rounded-md shadow-sm text-center pt-16 z-10">
          <p class="px-4">Außerdem sind wir:<br /><span class="text-2xl">Wakeboarder, Köche, Basketballer, Festivalnerds, Väter, Weltenbummler</span></p>
        </div>
        <img src="./../assets/img/aboutus/foto6.jpg" alt="Ausblick, wenn die Deadline näher kommt" title="Ausblick, wenn die Deadline näher kommt" class="col-span-1 shadow-sm rounded-md z-10" />
      </div>
      <h3 class="h3">Initiativbewerbung</h3>
      <p>(Noch) nicht das Richtige dabei? Keine Bange: Das muss nichts heißen. Vielleicht kannst Du uns trotzdem mit Deinen Learnings und Deiner Berufserfahrung unterstützen. Schick uns initiativ Deine Bewerbungsunterlagen zu.</p>
      <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 my-8" @clicked="onClick">Anker lichten</CtaButton>
    </div>

  </div>
</template>

<script>
import CtaButton from './../views/CtaButton.vue'
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'
import PrinciplesShort from './../views/PrinciplesShort.vue'

export default {
  name: 'Jobs',
  components: {
    CtaButton,
    Headline,
    Container,
    PrinciplesShort
  },
  data: () => {
    return {
      jobs: [
        {
          title: 'Senior Full-Stack Entwickler',
          routeName: 'fullstackdev',
          location: 'Vollzeit',
          when: 'Ab sofort oder flexibel',
          where: 'Hamburg/remote',
        },
        {
          title: 'Senior C# .NET Entwickler',
          routeName: 'dotnetdev',
          location: 'Vollzeit',
          when: 'Ab sofort oder flexibel',
          where: 'Hamburg/remote',
        }
      ]
    }
  },
  methods: {
    onClick() {
      window.location = 'mailto:dario@softwerkstatt.de'
    },
    onClickJob(routeName) {
      this.$router.push({name: routeName})
      this.scrollToTop()
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
    getImgUrl(icon) {
      if(!icon) {
        icon = 'icon_dotnet.png'
      }
    }
  }
}
</script>

<style scoped>
p {
  margin: 0.5em 0;
}
.container > h3 {
  margin-top: 1em;
}
</style>