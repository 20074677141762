<template>
  <Curved class="mx-auto sm:mt-16 md:pt-32 pb-12 md:pb-24 px-4 lg:px-0 pointer-events-none" :skipBorderTop="true" :usePaddingTop="true" :useBorder2img="useBorder2img" id="headerBgImage">
    <h1 v-if="!seoStyle2" :class="'block pre-headline text-center p'" style="color: #93c0d1">{{subtitle}}</h1>
    <h2 v-if="!seoStyle2" class="text-white h1 -mt-4 pb-4" :class="{'smallSizeMobile': smallSizeMobile}"><slot></slot></h2>
    <p v-if="seoStyle2" :class="'block pre-headline text-center p'" style="color: #93c0d1">{{subtitle}}</p>
    <h1 v-if="seoStyle2" class="text-white h1 -mt-4 pb-4" :class="{'smallSizeMobile': smallSizeMobile}"><slot></slot></h1>
  </Curved>
</template>

<script>
import Curved from './Curved.vue'

export default {
  name: 'Headline',
  components: {
    Curved
  },
  props: {
    subtitle: String,
    seoStyle2: Boolean,
    useBorder2img: Boolean,
    smallSizeMobile: Boolean
  },
  methods: {
  }
}
</script>

<style scoped>
.p {
  font-size: 1.2rem;
  line-height: 1.0em;
  padding: 0.5rem 0;
}
.h1 {
  font-size: 3.4rem;
  line-height: 1.1em;
  padding-top: 0.625rem;
  padding-bottom: 1.625rem;
}
@media (max-width: 640px) {
  .p {
    max-width: 60%;
    margin: 0 auto;
  }
  .h1 {
    font-size: 3.2rem;
  }
  .h1.smallSizeMobile {
    font-size: 2.4rem;
  }
}
#headerBgImage {
  background-image: url('./../assets/img/intro/header-3840px.jpg');
  background-size: cover;
}
@media (max-width: 1920px) {
  #headerBgImage {
    background-image: url('./../assets/img/intro/header-1920px.jpg');
  }
}
@media (max-width: 1366px) {
  #headerBgImage {
    background-image: url('./../assets/img/intro/header-1366px.jpg');
  }
}
@media (max-width: 768px) {
  #headerBgImage {
    background-image: url('./../assets/img/intro/header-mobile-1200px.jpg');
  }
}
</style>