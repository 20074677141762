<template>
  <header v-if="isDesktop || isOpened" class="h-16 -mt-16 w-full shadow z-40 fixed backdrop-filter backdrop-blur-md bg-[#214979] sm:bg-white bg-opacity-70">
    <div class="max-w-7xl mx-auto sm:flex h-16 mt-4 sm:mt-0">
      <router-link to="/" >
        <span v-on:click="isOpened = false, scrollToTop()" class="inline-block">
          <img class="flex-initial h-11 pl-2 xl:pl-0 pt-4 pr-16 ml-4 2xl:ml-0" src="./../assets/img/logo.png" alt="Softwerkstatt Digitalagentur Hamburg Logo">
        </span>
      </router-link>
      <ul class="flex-1 sm:text-right md:mr-4 lg:mr-16 h-full layer1 noselect">
        
        <li @mouseover="onMouseEnter(1)" @mouseleave="onMouseLeave()" :class="activeDropDown === 1 ? 'hover' : ''">
          <a><span>über <b>CHANGE</b></span></a>
          <ul class="layer2 rounded-b bg-white shadow-xl sm:shadow">
            <li :class="activeTitle.indexOf('Digitalisierung') !== -1 ? 'active' : ''"><router-link to="/so-geht-digitalisierung" v-on:click="isOpened = false, scrollToTop()">So geht Digitalisierung</router-link></li>
            <li :class="activeTitle.indexOf('Change') !== -1 ? 'active' : ''"><router-link to="/change-management-hamburg" v-on:click="isOpened = false, scrollToTop()">Change Management</router-link></li>
            <li :class="activeTitle.indexOf('Arbeitsplatz') !== -1 ? 'active' : ''"><router-link to="/arbeitsplatz-der-zukunft" v-on:click="isOpened = false, scrollToTop()">Arbeitsplatz der Zukunft</router-link></li>
            <li :class="activeTitle.indexOf('Lessons Learned') !== -1 ? 'active' : ''"><router-link to="/lessons-learned-best-practices" v-on:click="isOpened = false, scrollToTop()">Sharing is caring</router-link></li>
          </ul>
        </li>

        <li @mouseover="onMouseEnter(2)" @mouseleave="onMouseLeave()" :class="activeDropDown === 2 ? 'hover' : ''">
          <a><span>über <b>TECH</b></span></a>
          <ul class="layer2 rounded-b bg-white shadow-xl sm:shadow">
            <li :class="activeTitle.indexOf('Individualsoftware') !== -1 ? 'active' : ''"><router-link to="/individualsoftware-hamburg" v-on:click="isOpened = false, scrollToTop()">Individualsoftware</router-link></li>
            <li :class="activeTitle.indexOf('.NET') !== -1 ? 'active' : ''"><router-link to="/csharp-und-.net-entwicklung" v-on:click="isOpened = false, scrollToTop()">.NET Expertise</router-link></li>
          </ul>
        </li>

        <li @mouseover="onMouseEnter(3)" @mouseleave="onMouseLeave()" :class="activeDropDown === 3 ? 'hover' : ''">
          <a><span>über <b>SOFTWERKSTATT</b></span></a>
          <ul class="layer2 rounded-b bg-white shadow-xl sm:shadow">
            <li :class="activeTitle.indexOf('Das sind wir') !== -1 ? 'active' : ''"><router-link to="/wer-wir-sind" v-on:click="isOpened = false, scrollToTop()">Das sind wir</router-link></li>
            <li :class="activeTitle.indexOf('Unsere Vision') !== -1 ? 'active' : ''"><router-link to="/unsere-vision" v-on:click="isOpened = false, scrollToTop()">Unsere Prinzipien</router-link></li>
            <li :class="activeTitle.indexOf('Handwerk') !== -1 ? 'active' : ''"><router-link to="/handwerk" v-on:click="isOpened = false, scrollToTop()">Unser Handwerk</router-link></li>
            <li :class="activeTitle.indexOf('New Work') !== -1 ? 'active' : ''"><router-link to="/new-work" v-on:click="isOpened = false, scrollToTop()">New Work</router-link></li>
            <li :class="activeTitle.indexOf('It’s a match') !== -1 ? 'active' : ''"><router-link to="/its-a-match" v-on:click="isOpened = false, scrollToTop()">It’s a Match!</router-link></li>
            <li :class="activeTitle.indexOf('Entwickler') !== -1 || activeTitle.indexOf('Jobs') !== -1 ? 'active' : ''"><router-link to="/jobs" v-on:click="isOpened = false, scrollToTop()">Jobs entdecken (2)</router-link></li>
            <li :class="activeTitle.indexOf('Kontakt') !== -1 ? 'active' : ''"><router-link to="/kontakt" v-on:click="isOpened = false, scrollToTop()">Kontakt</router-link></li>
          </ul>
        </li>
        
        <li class="hidden lg:inline-block">
          <CtaButton class="hover-my-gradient2 hover:border-transparent border-gray-500 hover:text-white text-gray-700 !py-1 rounded-full mt-3 hover:scale-110" @clicked="onClickCta">Anheuern</CtaButton>
        </li>

      </ul>
    </div>
  </header>
  <div v-if="!isOpened" class="fixed sm:hidden top-0 right-1 mt-4 mr-4 z-40 rounded-full shadow hover:shadow-lg bg-white text-gray-500 transform transition-all duration-400 hover:scale-110" id="iconOpen">
    <div :class="'p-2 transform transition-all duration-400 hover:-rotate-6 ' + (isOnTop ? 'text-white' : '')" y>
      <svg v-on:click="isOpened = !isOpened" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="cursor-pointer"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
    </div>
  </div>

  <div v-if="isOpened" class="fixed sm:hidden top-0 right-2 mt-4 mr-4 z-40 text-white transform transition-all duration-400 hover:scale-110" id="iconClose">
    <svg v-on:click="isOpened = !isOpened" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="cursor-pointer text-white">
      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
    </svg>
  </div>
</template>

<script>
import CtaButton from './CtaButton.vue'

export default {
  name: 'MyHeader',
  components: {
    CtaButton,
  },
  data: () => {
    return {
      activeTitle: '',
      activeDropDown: -1,
      isOpened: false,
      isDesktop: true
    }
  },
  watch: {
    '$route' (to) {
      this.activeTitle = to.meta.title
    }
  },
  created() {
    window.addEventListener("resize", this.detectDesktop);
    this.detectDesktop();
  },
  unmounted() {
    window.removeEventListener("resize", this.detectDesktop);
  },
  methods: {
    detectDesktop() {
      this.isDesktop = window.innerWidth >= 640;
      if(this.isDesktop) {
        this.isOpened = false;
      }
    },
    scrollToTop() {
      window.scrollTo(0,0)
      this.activeDropDown = -1
    },
    onMouseEnter(i) {
      this.activeDropDown = i
    },
    onMouseLeave() {
      this.activeDropDown = -1
    },
    onClickCta() {
      this.$router.push({path: '/jobs'})
      this.scrollToTop()
    }
  }
}
</script>

<style scoped>

/* MOBILE */
@media (max-width: 640px) {
  #iconOpen {
    width: 3.5vw;
    max-width: 50px;
    min-width: 40px;
  }
  #iconClose {
    width: 3.5vw;
    max-width: 60px;
    min-width: 50px;
  }
  header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    margin-top: 0;
    font-size: 1.5em;
  }
  ul.layer1 {
    margin: 0 20px;
  }
  ul.layer1 > li {
    margin: 10px 0;
  }
  ul.layer1 > li > a {
    color: #fff;
  }
  ul.layer1 > li > ul {
    padding: 10px;
  }
  span {
    font-size: 1.0em;
  }
  a {
    text-decoration: none;
  }
  li.active a span {
    color: #68D7E4;
    text-decoration: underline;
  }
}

/* DESKTOP */
@media (min-width: 640px) {
  ul.layer1 > li {
    line-height: 1.8em;
    height: 4rem;
    vertical-align: top;
  }
  ul.layer1 > li:not(:last-child) {
    display: inline-block;
  }
  ul.layer1 > li:nth-child(1) { width: 175px; }
  ul.layer1 > li:nth-child(2) { width: 155px; }
  ul.layer1 > li:nth-child(3) { width: 240px; }
  ul.layer1 > li:last-child { width: 185px; }
  ul.layer1 > li > a {
    text-decoration: none;
    font-size: 0.8rem;
    font-family: "Poppins";
    position: relative;
    display: inline-block;
    height: 4rem;
    padding: 1.0rem 1.25rem 0 1.25rem;
    -webkit-tap-highlight-color: transparent;
    font-weight: 300;
    color: #333;
    /* text-transform: uppercase; */
  }
  ul.layer1 > li > a > span > b {
    font-size: 0.9rem;
    font-weight: 700;
  }
  ul.layer1 > li > a:not([href]) {
    cursor: default;
  }
  ul.layer1 > li > a span {
    position: inherit;
    display: inline-block;
    transition: all 0.4s;
    padding: 0 1rem;
  }
  ul.layer1 > li > a:hover {
    font-weight: 300;
    color: #fff;
  }
  ul.layer1 > li > a:hover span {
    transform: scale(1.3);
  }
  ul.layer1 > li > a:hover span > b {
    font-weight: 500;
  }
  ul.layer1 > li > a:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    transform: skew(-12deg);
    z-index: -1;
    background: rgba(255,255,255,0.0);
    left: 50%;
    right: 50%;
    transition: background 0.5s, right 0.3s, left 0.3s;
  }
  ul.layer1 > li > a:hover:before {
    background: rgba(0, 83, 133, 1);
    z-index: 0;
    left: -20px;
    right: -20px;
  }
  /* LAYER 2 */
  ul.layer2 {
    margin-left: -12px;
    width: 258px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.15s;
  }
  :hover ul.layer2 {
  }
  ul.layer1 > li:nth-child(2) > ul.layer2 {
    width: 230px;
  }
  ul.layer1 > li:nth-child(3) > ul.layer2 {
    transition: all 0.3s;
    width: 230px;
  }
  ul.layer1 > li:nth-child(1).hover > ul.layer2 {
    max-height: 230px; /* [pages]x50 + 30 */
  }
  ul.layer1 > li:nth-child(2).hover > ul.layer2 {
    max-height: 180px; /* [pages]x50 + 30 */
  }
  ul.layer1 > li:nth-child(3).hover > ul.layer2 {
    max-height: 430px; /* [pages]x50 + 30 */
  }
    ul.layer2 > li {
      height: 50px;
      line-height: 50px;
      padding-left: 20px;
      font-size: 0.8em;
      position: relative;
      text-align: left;
      font-family: "Poppins";
    }
    ul.layer2 > li:first-child {
      margin-top: 13px;
    }
    ul.layer2 > li:last-child {
      margin-bottom: 13px;
    }

    ul.layer2 > li > a {
      text-decoration: none;
      display: inline-block;
      width: 100%;
    }
    ul.layer2 > li > a:before {
      content: '';
      width: 5px;
      background-color: #5696e4;
      position: absolute;
      display: block;
      top: 0;
      left: 3px;
      height: 0;
      transition: all 0.2s;
      opacity: 0;
    }
    ul.layer2 > li.active > a {
      color: #0291D8;
    }
    ul.layer2 > li.active > a:before,
    ul.layer2 > li:hover > a:before {
      height: 100%;
      opacity: 1;
    }
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
}
</style>