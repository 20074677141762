<template>
  <Curved class="gradient absolute-important left-0 w-full -z-10 -bottom-470" :skipBorderBottom="true" :fixed="true">
    <footer class="container max-w-screen-xl 2xl:max-w-screen-2xl flex flex-col md:flex-row px-12 md:px-16 pb-12 z-10">
      
      <div class="flex-initial max-w-md md:pb-4 order-2 md:order-1 lg:pt-7">
        <p class="mb-2 text-3xl text-white hidden md:block">Komm an Bord, Matrose</p>
        <p class="mb-4 md:mb-0 hidden md:block">Unser junges Startup sucht stetig neue Talente. Du hast in C# und .NET echt was auf dem Kasten und suchst eine Position mit Potenzial zur Weiterentwicklung? Dann lass uns bei einem Schnack unter Deck kennenlernen.</p>
        <ul>
          <li><router-link to="/jobs"><span v-on:click="scrollToTop">Jobs entdecken (2)</span></router-link></li>
          <li><a href="/Softwerkstatt AGB.pdf">AGB</a> | <router-link to="/privacy-policy"><span v-on:click="scrollToTop">Datenschutz</span></router-link> | <router-link to="/impressum"><span v-on:click="scrollToTop">Impressum</span></router-link></li>
          <li class="robots-nocontent">
            <a href="https://github.com/softwerkstatt-dev" target="_blank">GitHub</a>
            <!-- | <a href="https://www.youtube.com/@Softwerkstatt" target="_blank">YouTube</a> -->
            | <a href="https://www.udemy.com/user/lasse-kluver-3/" target="_blank">Udemy</a>
            | <a href="http://linkedin.com/company/softwerkstatt" target="_blank">LinkedIn</a>
          </li>
        </ul>
      </div>

      <div class="hidden lg:block order-2 flex-1 md:pl-8 lg:pl-16 2xl:pl-48">
      </div>

      <div class="flex-initial max-w-md lg:max-w-lg md:pb-4 md:text-right order-1 md:order-3">
        <h3 class="mb-2 text-3xl text-white">Softwareentwicklung made in Hamburg</h3>
        <p class="xl:max-w-lg ml-auto">Wir digitalisieren als Hamburger Dienstleister Eure Arbeitsabläufe und Change Prozesse für ganzheitliche Transformation. In der Programmierung stehen unsere C# .NET Softwareentwickler im gesamten Prozess von A wie Azure bis Z wie ZSkalierung beratend zur Seite.</p>
        <ul class="my-4 md:my-0">
          
          <li><a href="tel:040800084851"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 md:h-8 w-6 md:w-8 inline-block md:align-top mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>040 / 800 084 851</a></li>
          <li><a href="mailto:moin@softwerkstatt.de"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 md:h-8 w-6 md:w-8 inline-block md:align-top mr-1" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
          </svg>moin@softwerkstatt.de</a></li>
          <li>Am Sandtorkai 77, 20457 Hamburg</li>
        </ul>
      </div>

    </footer>
  </Curved>
</template>

<script>
import Curved from './Curved.vue'
export default {
  name: 'MyFooter',
  components: {
    Curved
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
footer {
  font-size: 0.95em;
}
.-z-10 {
  z-index: -10;
}
.-bottom-470 {
  bottom: -520px;
}
@media (max-width: 768px) {
  .-bottom-470 {
    bottom: -470px;
  }
}
.absolute-important {
  position: absolute !important;
}
p, li {
  color: white;
}
.gradient {
  background: linear-gradient(45deg, #36c1c7 0%, #67dfc4 90%) !important;
}
a:hover {
  color: #015086;
}
h4 {
  margin-bottom: 1rem;
}
@media (min-width: 768px) {
  ul {
    margin-top: 1rem;
  }
}
</style>
