<template>
  <Headline :subtitle="'Softwerkstatt anfunken'" :useBorder2img="false">Kontakt</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5" style="min-height: calc(100vh - 350px)">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8 xl:mb-16">
    
      <div class="lg:text-center">
        <p class="inline-block p max-w-lg pb-4 md:pb-16 sm:pb-12 mx-auto"><i>„Coming together is a beginning. Keeping together is progress. Working together is success.”</i> ― Henry Ford, Engineer</p>
      </div>
      <p>Softwerkstatt ist eingebettet in Hamburg. Von der historische Speicherstadt von 1888, Hamburgs Weltkulturerbe und Wahrzeichen der Hansestadt. Bis nach St. Pauli, ehemaliger Vorort Hamburgs und Kreativ- und Künstlerviertel. Von hier aus haben wir den perfekten Blick, um als Schlepper zu begleiten und gemeinsam Kurs auf Eure Digitalisierung zu nehmen.</p>
    </div>

    <div>
      <div class="gradient h-24 lg:h-96"></div>
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8 -mt-16 lg:-mt-80 lg:text-white">
        <ContactForm />
      </div>
    </div>
    
    <div class="container max-w-screen-lg 2xl:max-w-screen-xl py-6 sm:py-0 pb-4 px-4">
      <ContactInfos />
    </div>
    
  </div>
</template>

<script>
import Headline from '../views/Headline.vue'
import ContactInfos from './../views/ContactInfos.vue'
import ContactForm from './../views/ContactForm.vue'

export default {
  name: 'Contact',
  components: {
    Headline,
    ContactInfos,
    ContactForm,
  },
  methods: {
  }
}
</script>

<style scoped>
h3 {
  margin: 0;
}
p, ul {
  margin-top: 1.0rem;
}
.gradient {
  background: linear-gradient(45deg, #56a5cd 0%, #67dfc4 90%) !important;
}
</style>