<template>
  <Curved class="gradient min-h-0 overflow-hidden">
    <div class="container xl:max-w-screen-lg 2xl:max-w-screen-xl mx-auto mt-6 sm:mt-8 lg:mt-12 xl:mt-20 mb-16 sm:mb-20 lg:mb-20 xl:mb-20 clearfix 2xl:relative">
      <div class="absolute -left-[80%] sm:left-0 -right-[28%] sm:right-0 -top-4 sm:-top-16 xl:-top-6">
        <img src="./../assets/img/leuchtturm-lichtschein-softwerkstatt-intro.webp" alt="Leuchtturm der Softwerkstatt Softwareentwicklungsagentur in Hamburg" class="w-full">
      </div>
      <div class="sm:w-3/5 3xl:w-3/4 xl:-mt-4 px-4 md:px-0 text-white ml-0 md:ml-2 2xl:ml-12 relative">
        <span class="text-2xl uppercase">Wir haben eine Vision</span>
        <h2 class="w-4/5 sm:w-full font-bold mt-2 mb-4 sm:mb-6 text-5xl sm:text-3xl lg:text-4xl 2xl:text-5xl text-white uppercase" id="headline">
          <div>Eure Digital<div class="block sm:hidden"></div>isierung</div>
          und unser Handwerk<br />
        </h2>
        <p class="text-xl xl:text-2xl lg:max-w-2xl text-white mb-4">Wir kombinieren das Beste aus zwei Welten: Dem Change Management & der Softwareentwicklung. Leinen los!</p>
        <CtaButton class="bg-white bg-opacity-20 shadow-none rounded-lg sm:text-2xl" @clicked="onClick">Das sind wir</CtaButton>
      </div>
    </div>
  </Curved>
</template>

<script>
import Curved from './Curved.vue'
import CtaButton from './CtaButton.vue'

export default {
  name: 'Intro',
  components: {
    Curved,
    CtaButton
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    },
    onClick() {
      this.$router.push({name: 'aboutus'})
      this.scrollToTop()
    }
  }
}
</script>

<style scoped>
.gradient {
  background: linear-gradient(45deg, #36c1c7 0%, #67dfc4 90%) !important;
}
h2 {
  font-weight: normal;
  font-family: "Poppins";
  font-size: 3.2em;
  line-height: 4.0rem;
  font-weight: 300;
}
@media (max-width: 1024px) {
  #headline {
    line-height: 1.2em;
  }
}
@media (max-width: 640px) {
  #headline {
    line-height: 0.95em;
  }
}
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>