<template>
  <Headline :subtitle="'Unsere Werkstatt für Eure Software'" :seoStyle2="true">So geht Digitalisierung</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <div class="text-center">
        <p class="inline-block max-w-sm pb-4 sm:pb-12 md:pb-16 mx-auto"><i>„Das Internet ist für uns alle Neuland.”</i> ― Angela Merkel, ehem. Physikerin</p>
      </div>
      <div class="md:flex">
        <div class="md:w-5/12 lg:w-7/12 lg:pr-16 2xl:mt-16">
          <h3 class="h3 title">Kein Neuland für Euch?</h3>
          <p class="text-2xl mt-6 max-w-md">Mit leicht erweiterbarer Software lassen sich Arbeitsabläufe effizient optimieren.</p>
          <ul class="checklist darkblue mb-4 2xl:mb-8" style="color: #214979;">
            <li>Entwicklung eines Prototypes und MVP</li>
            <li>Zeitersparnis durch schlankere Prozesse</li>
            <li>Erweiterbar und anpassungsfähig</li>
          </ul>
          <CtaButton class="hidden sm:inline shadow-none border-white text-white special-button hover:text-white mx-auto" @clicked="onClick">Kontakt aufnehmen</CtaButton>
        </div>
        <div class="w-9/12 md:w-7/12 lg:w-5/12 px-8" id="digitalizeContainer">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 990 1002" >
            <image width="990" height="1002" xlink:href="./../assets/img/digitalize/base.png"></image>
            <image width="990" height="1002" v-if="showArea == 1" xlink:href="./../assets/img/digitalize/1.png"></image>
            <image width="990" height="1002" v-if="showArea == 2" xlink:href="./../assets/img/digitalize/2.png"></image>
            <image width="990" height="1002" v-if="showArea == 3" xlink:href="./../assets/img/digitalize/3.png"></image>
            <image width="990" height="1002" v-if="showArea == 4" xlink:href="./../assets/img/digitalize/4.png"></image>
            <image width="990" height="1002" v-if="showArea == 5" xlink:href="./../assets/img/digitalize/5.png"></image>
            <image width="990" height="1002" v-if="showArea == 6" xlink:href="./../assets/img/digitalize/6.png"></image>
            <image width="990" height="1002" v-if="showArea == 7" xlink:href="./../assets/img/digitalize/7.png"></image>
            <polygon @mouseover="overArea(1)" @mouseleave="leaveArea(1)" points="125,187,204,137,297,90,405,53,504,26,504,214,428,221,338,240,281,264,250,289" class="area" />
            <polygon @mouseover="overArea(2)" @mouseleave="leaveArea(2)" points="516,23,596,9,678,1,780,2,865,24,912,52,946,95,964,133,750,306,734,272,694,243,638,222,575,215,516,213" class="area" />
            <polygon @mouseover="overArea(3)" @mouseleave="leaveArea(3)" points="751,316,969,147,987,255,987,373,971,484,944,595,700,540,733,466,747,404,755,356" class="area" />
            <polygon @mouseover="overArea(4)" @mouseleave="leaveArea(4)" points="693,550,943,607,881,773,832,863,787,923,740,966,602,679,643,635,673,591" class="area" />
            <polygon @mouseover="overArea(5)" @mouseleave="leaveArea(5)" points="593,689,730,976,677,998,617,1001,559,988,490,960,410,911,342,867,427,691,469,717,505,727,543,725" class="area" />
            <polygon @mouseover="overArea(6)" @mouseleave="leaveArea(6)" points="414,683,332,858,224,762,146,675,94,604,299,555,329,602,364,637" class="area" />
            <polygon @mouseover="overArea(7)" @mouseleave="leaveArea(7)" points="292,545,85,592,19,474,1,395,4,339,15,304,40,265,78,223,113,194,241,296,227,318,220,359,228,406,251,471" class="area" />
          </svg>
          <div class="text-3xl xl:h2 absolute w-44 text-center xl:ml-12 2xl:ml-16 left-1/3 md:pt-4 lg:pt-0 top-1/4 xl:top-1/3 border border-[#015086] rounded-full inline ml-10 w-[2.5rem]" id="digitalizeText1" :class="showArea != -1 ? 'active' : ''">{{showArea != -1 ? showArea : ''}}</div>
          <div class="text-3xl xl:h2 absolute w-44 text-center xl:-ml-4 2xl:ml-0 left-1/3 md:pt-4 lg:pt-0 top-1/4 xl:top-1/3" id="digitalizeText2" :class="showArea != -1 ? 'active' : ''">{{text}}</div>
          <div class="text-3xl xl:h2 absolute w-44 text-center xl:-ml-4 2xl:ml-0 left-1/3 md:pt-4 lg:pt-0 top-1/4 xl:top-1/3" id="digitalizeText3" :class="showArea == -1 ? 'active' : ''">Unsere 7 Projektschritte</div>
        </div>
      </div>
    </div>

    <Services3 class="mb-4 md:mb-12 lg:mb-16 mt-32" />
    
    <Container class="" :styles="'py-6 sm:pb-8 px-4 lg:px-0'">
      <h2 class="h1 mx-auto">Volle Kraft voraus</h2>
      <div class="text-center">
        <p class="inline-block max-w-4xl mx-auto">Eure Idee benötigt den ersten Energieschub zum Start in die Umsetzung? Unsere Softwareentwickler stehen auch projektbegleitend zur Seite und beraten auf dem Weg zur Umsetzung und zum Livegang.</p>
      </div>
      <div class="md:flex text-center lg:mx-16 mt-8 lg:mt-12 mb-12">
        <div class="flex-1 hover:bg-gray-100 rounded pt-4">
          <img src="./../assets/img/icons/lupe.svg" class="mx-auto h-16 lg:h-28" alt="">
          <h3 class="text-xl w-9/12 2xl:w-7/12 mx-auto mt-4 mb-4">1. Wir analysieren Eure aktuelle Herausforderung</h3>
        </div>
        <div class="flex-1 hover:bg-gray-100 rounded pt-4">
          <img src="./../assets/img/icons/gehirn.svg" class="mx-auto h-16 lg:h-28" alt="">
          <h3 class="text-xl w-9/12 2xl:w-7/12 mx-auto mt-4 mb-4">2. Gemeinsam entwickeln wir eine langfristige Lösung</h3>
        </div>
        <div class="flex-1 hover:bg-gray-100 rounded pt-4">
          <img src="./../assets/img/icons/rakete2.svg" class="mx-auto h-16 lg:h-28" alt="">
          <h3 class="text-xl w-9/12 2xl:w-7/12 mx-auto mt-4 mb-4">3. Wir unterstützen beim Testing und Projektlaunch</h3>
        </div>
      </div>
      <div class="text-center">
        <CtaButton class="block sm:inline border-gray-500 text-gray-700 hover-my-gradient hover:border-transparent hover:text-white" @clicked="onClick">Kontakt aufnehmen</CtaButton>
      </div>
    </Container>

    <p class="px-4 sm:px-0 w-full md:max-w-xl mx-auto text-left sm:text-center sm:pt-4 lg:pt-8"><i>„Ich glaube, dass es auf der Welt einen Bedarf von vielleicht fünf Computern geben wird.“</i> - Thomas John Watson, IBM</p>
    
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <ContactInfos />
    </div>
  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import ContactInfos from './../views/ContactInfos.vue'
import Services3 from './../views/Services3.vue'
import CtaButton from './../views/CtaButton.vue'
import Container from './../views/Container.vue'

export default {
  name: 'WhatWeDo',
  components: {
    Headline,
    ContactInfos,
    CtaButton,
    Services3,
    Container,
  },
  data: () => {
    return {
      highlight: 1,
      showArea: -1,
      defaultText: [
        'Usability Konzept',
        'Prototyp entwickeln',
        'Bottlenecks finden',
        'Feedback sammeln',
        'Software verbessern',
        'Softlaunch',
        'Langfristig optimieren',
      ],
      text: '',
      timelineText: [
        'Verstehen: Analyse des Konzepts und Aufbereitung der Anforderungen',
        'Beobachten: Einarbeitung in Spezifikation und frühzeitige Erkennung von Bottlenecks',
        'Definieren: Entwicklung eines MVP und Prototypen, Einholen von Nutzer-Feedback',
        'Entscheiden: Treffen von Architektur, Design- und Technologie-Entscheidungen',
        'Entwickeln: Programmierung von Frontend, Backend und Datenbankstruktur',
        'Testen: Sicherstellen der Qualitätssicherung sowie Feedback-Schleifen mit Nutzern',
        'Projektlaunch: Veröffentlichung, Fehlerbehebungen und langfristige Wartung'
      ]
    }
  },
  methods: {
    overItem(index) {
      this.highlight = index;
    },
    scrollToTop() {
      window.scrollTo(0,0)
    },
    onClick() {
      this.$router.push({name: 'contact'})
      this.scrollToTop()
    },
    overArea(index) {
      this.showArea = index
      this.text = this.defaultText[index - 1]
    },
    leaveArea(index) {
      if(this.showArea != index) {
        return
      }
      this.showArea = -1
    }
  }
}
</script>

<style scoped>
p {
  margin-top: 1rem;
}
ul.white li span {
  color: rgba(156, 163, 175, 1);
}
.my-list li {
  position: relative;
  padding: 8px 0;
  opacity: 0.5;
}
.my-list li div {
  display: inline-block;
  margin-right: 3.0rem;
}
.my-list li div:after {
  content: '';
  position: absolute;
  display: block;
  left: 22px;
  top: 23px;
  width: 25px;
  height: 2px;
  opacity: 0.7;
  background-color: #22F0E3;
}
.my-list li.active {
  opacity: 1;
}

.area {
   fill:lime;
   stroke: purple;
   stroke-width: 5;
   opacity: 0; /* TESTING */
   fill-rule: evenodd;
   cursor: pointer;
}
#digitalizeContainer {
  position: relative;
}
  #digitalizeContainer > div {
    transition: all 0.2s;
    opacity: 0;
  }
  #digitalizeContainer > div.active {
    opacity: 1;
  }
  #digitalizeText1 {
    margin-top: -6%;
    color: #417F99;
    transition: all 0.2s;
  }
  #digitalizeText2 {
    margin-top: 2%;
    line-height: 1.0em;
  }
  #digitalizeText3 {
    margin-top: -4%;
  }
</style>