<template>
  <Headline :subtitle="'Konzipiere und vertreibe Apps & Web-Plattformen'" :useBorder2img="true">Vertriebs- und Projektmanager/in gesucht</Headline>
  <div class="pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0 mb-8">
      <p>Du bist jemand, der als Allrounder den Softwareentwicklungs-Lifecycle von Anfang bis Ende betreuen möchte? Von Akquise, über Angebote schreiben bis Kundenkontakt. Wir suchen jemanden, der keine kleinen Zahnräder dreht, sondern ganze Schiff lenkt. Da wir gerade erst gegründet und noch ein kleines Team sind, wirst Du alle Projekte und das gesamte Unternehmen durch Deine Arbeit mitgestalten. Klingt nach Abenteuer auf hoher See?</p>
      <img src="./../assets/img/jobs_1.png" alt="Was wir bieten">
      <h2 class="h2">Jobbeschreibung</h2>
      <ul class="numberslist">
        <li><b>Vertrieb:</b> Alles fängt bei der Akquise an: Du bist für den gesamten Verkaufsprozess zuständig und entscheidend verantwortlich. Du arbeitest mit uns zusammen, um gemeinsam Kunden zu gewinnen, Netzwerk zu pflegen und Aufträge an Land zu ziehen.</li>
        <li><b>Projektmanagement:</b> Du wirst Projekte konzipieren und mit dem Grafike zusammen Prototypen erstellen, diese in Iterationen basierend auf Feedback des Kunden verbessern und schließlich während der Umsetzung die Kommunikation zwischen Kunden und IT bis zum Go-Live betreuen. Du entscheidest, wie sich das ganze Projekt entwickeln wird.</li>
      </ul>
    </div>
    
    <Curved>
      <div class="container mx-auto max-w-screen-lg pt-8 px-4 lg:px-0">
        <h2 class="h2">Deine Aufgaben:</h2>
        <ul class="list">
          <li>Projekt-Akquise und Technischer Vertrieb / Sales</li>
          <li>Erstellung von Konzepten für Websites / Apps / Backend</li>
          <li>Kommunikation mit Kunden</li>
          <li>Kommunikation mit IT, Grafiker, QA</li>
        </ul>
      </div>
    </Curved>

    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0">
      <p class="max-w-3xl">Wir setzen auf Kandan und Scrum, sehen aber vor allem in Konzeptions- und Prototypeing-Phasen klassische Wasserfall-Vorgehensweisen als sinnvoll. Du bist Teil des Teams in Hamburg und betreust den Vertrieb, schreibt (mit uns zusammen) Angebote, und (wenn's dann hoffentlich was wird), betreust das Projekt in der Umsetzung (nicht alleine).</p>
      <h2 class="h2">Das solltest Du mitbringen:</h2>
      <ul class="checklist">
        <li>Lernbereitschaft</li>
        <li>Leidenschaft und Spaß am Erfinden von Produkten</li>
        <li>Mind. 3 Jahre Berufserfahrung im Projektmanagement mit Softwareprojekten</li>
        <li>Technisches Verständnis rund um Technologien für Websites, Apps und Backends</li>
        <li>Du hast bereits Konzepte für Softwareprojekte geschrieben</li>
        <li>Du hast bereits Kunden betreut</li>
        <li>Strukturierte und analytische Vorgehensweise</li>
        <li>Eigenverantwortliches Handeln: „you build it, you run it!“</li>
      </ul>
      <h2 class="h2 mt-8">Das bieten wir Dir:</h2>
    </div>

    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_school.png" alt="Weiterbildung" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Weiterbildungen &amp; ständige Neues lernen</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_fork.png" alt="Keine Hierarchien" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Keine Hierarchien, jeder ist Leader</span></div>
      </div>
    </div>
    <div class="border-t-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_clock.png" alt="Flexible Arbeitszeiten" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Flexible Arbeitszeiten und Arbeitsort</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_people.png" alt="Kleines Team" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Kleines Team und Eigenverantwortung</span></div>
      </div>
    </div>
    <div class="border-t-2 border-b-2 border-gray-300 pl-4 lg:px-0">
      <div class="container mx-auto max-w-screen-lg flex">
        <div class="flex-1 py-4 border-r-2 border-gray-300 text-center sm:text-left"><img src="./../assets/img/icons/icon_money.png" alt="Faires Gehalt" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Faires Gehalt</span></div>
        <div class="flex-1 py-4 pl-4 text-center sm:text-left"><img src="./../assets/img/icons/icon_company.png" alt="Direkte Einflussnahme auf Projekt und Firma" class="h-20 mx-auto sm:mx-0 sm:inline sm:pr-4" /><span>Einflussnahme auf Projekte und auf's Unternehmen</span></div>
      </div>
    </div>

    <div class="container mx-auto max-w-screen-lg px-4 lg:px-0 my-8">
      <p>Wir möchten, dass Du Spaß und Freude an der Arbeit und dem Erfinden von Softwareprodukten hast. Bei uns gibt es <b>keine</b> Hierarchien und kurze Entscheidungswege. Interessante, regelmäßig wechselnde Aufgaben mit einem hohen Maß an Gestaltungsspielraum. Und da wir (noch) eine kleine Firma sind, geht es recht familiär zu: Entspannte Atmosphäre und ein kleines Team - kein Arbeiten wie im Konzern. Du bist herzlich Willkommen.</p>
      <h3 class="h3">Das solltest Du wissen:</h3>
      <ul class="list">
        <li>Wir wünschen uns, diese Position in <b>-1 bis 3 Monaten</b> zu besetzen.</li>
        <li>Vollzeit (oder mindestens Teilzeit)</li>
        <li>Mac, Linux oder Windows? Ist uns egal, Dein Betriebssystem suchst Du Dir selbst aus. Dein Notebook auch - von uns gestellt.</li>
        <li>Tools und Programme kannst Du Dir selbstverständlich auch selbst aussuchen.</li>
        <li>Tool X oder Technologie Y sind für Dich Neuland? Solltest Du weiterführende Kenntnisse benötigen, bieten wir Dir gern entsprechende Weiterbildungsmöglichkeiten, z.B. über Autausch mit Deinen Kollegen oder bezahlte Video-Kurse, an.</li>
        <li>Im derzeitigen Umfeld wünschen sich die Meisten, <b>remote</b> zu arbeiten. Das ist natürlich kein Problem. Wir haben ein kleines Büro in Hamburg Eimsbüttel (nicht die Adresse im Impressum), das aber kaum besetzt ist.</li>
      </ul>
      <h3 class="h3">Hört sich gut an?</h3>
      <p>Sende uns einfach Deinen Lebenslauf mit möglichem Eintrittstermin und Gehaltswunsch.</p>
      <CtaButton class="my-gradient hover-no-gradient border-transparent hover:border-gray-500 text-white hover:text-gray-700 mt-8" @clicked="onClick">Jetzt bewerben</CtaButton>
    </div>

  </div>
</template>

<script>
import CtaButton from './../views/CtaButton.vue'
import Curved from './../views/Curved.vue'
import Headline from './../views/Headline.vue'

export default {
  name: 'ProjectManager',
  components: {
    CtaButton,
    Headline,
    Curved
  },
  methods: {
    onClick() {
      window.location = 'mailto:dario@softwerkstatt.de'
    }
  }
}
</script>

<style scoped>
h2, h3 {
  color: #5DCAC7;
}
p, ul {
  margin: 1rem 0;
}
</style>