<template>
  <div class="hidden md:grid w-full h-12 border-b border-gray-300 mb-16 text-center" :class="'grid-cols-' + values.length">
    <div class="text-lg pl-4 pt-3 hover:border-b-2 border-gray-300 cursor-pointer" :class="{'border-b-2 border-[#0291D8]': active == index}" v-on:click="onClickIndex(index)" v-for="(item, index) in values" v-bind:key="index" v-html="item.title"></div>
  </div>
  <div class="flex">
    <div class="hidden md:block flex-1 mt-12 lg:mt-24 -ml-2 md:ml-0 mr-1 md:mr-4">
      <svg class="custom-icon cursor-pointer p-1 text-gray-500 transform transition-transform scale-130 hover-text-blue" @click="clickedLeft" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
    </div>
    <ul class="flex-1 md:flex-initial max-w-3xl text-left" id="slideshow">
      <article class="border-b-2 border-gray-100 md:border-0" :class="{'active': active == index}" v-for="(text, index) in values" :key="text.title">
        <h3 :class="largeHeadline ? 'h1 lg:text-center my-2 md:mb-8 md:mt-0' : 'h3 mb-2'" v-on:click="onClickHeadline(index)" v-html="text.title"></h3>
        <span class="icon block sm:hidden text-gray-400"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" /></svg></span>
        <div :class="active == index ? 'inline-block' : 'hidden lg:inline'">
          <p class="inline" v-html="text.desc1"></p>
          <p :class="active == index && readMore ? 'inline' : 'hidden lg:inline'" v-html="text.desc2"></p>
          <span class="inline-block lg:hidden px-2 ml-2 text-bold border-2 border-white rounded cursor-pointer" v-if="text.desc2 != '' && !readMore" v-on:click="readMore = true">mehr<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" height="12" width="12" class="inline-block align-text-middle"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" /></svg></span>
        </div>
      </article>
    </ul>
    <div class="hidden md:block flex-1 mt-12 lg:mt-24 -mr-2 md:mr-0 ml-1 md:ml-4">
      <svg class="custom-icon cursor-pointer p-1 text-gray-500 transform transition-transform scale-130 hover-text-blue float-right" @click="clickedRight" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1" d="M14 5l7 7m0 0l-7 7m7-7H3" />
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Slideshow',
  props: {
    values: Boolean,
    largeHeadline: Boolean
  },
  data: () => {
    return {
      active: 0,
      readMore: false
    }
  },
  methods: {
    onClick(id) {
      this.active = id; 
    },
    clickedLeft() {
      this.active--;
      if(this.active == -1) {
        this.active = this.values.length - 1;
      }
    },
    clickedRight() {
      this.active++;
      if(this.active >= this.values.length) {
        this.active = 0;
      }
    },
    onClickHeadline(id) {
      var isDesktop = window.innerWidth >= 768;
      if(isDesktop) {
        return;
      }
      this.active = this.active == id ? -1 : id;
      this.readMore = false
    },
    onClickIndex(index) {
      this.active = index;
    },
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
.custom-icon {
  width: 75px;
  max-width: 100%;
  min-width: 40px;
}
.hover-text-blue:hover {
  color: #0291D8;
}
@media (min-width: 768px) {
  ul article,
  ul li {
    display: none;
  }
  ul article.active,
  ul li.active {
    display: block;
  }
  h3 {
    margin-top: -10px;
  }
}
@media (max-width: 768px) {
  article {
    position: relative;
  }
  h3 {
    position: relative;
    z-index: 10;
    cursor: pointer;
  }
  article.active h3 {
    font-weight: bold;
  }
  .icon {
    position: absolute;
    right: 0;
    top: 5px;
    width: 30px;
    height: 30px;
    color: #fff;
  }
  article.active .icon {
    transform: rotate(180deg);
  }
  p {
    margin-bottom: 20px;
  }
  article div {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem 0;
  }
}
</style>