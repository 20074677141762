<template>
  <Headline :subtitle="'Wir teilen unsere Lessons Learned'" :seoStyle2="true">Sharing is caring</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      <div class="lg:text-center">
        <p class="inline-block p max-w-2xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„The best thing about sharing is the joy it brings to others. It's not just about making a donation. It’s about making a difference.”</i> ― Unknown, philosopher</p>
      </div>
    </div>
    
    <section>
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h2 class="h3">Erwartungen genau verstehen</h2>
          <ul class="checklist">
            <li>Wir wollen nicht nur blind abarbeiten, was Auftraggeber fordern. Wir haken so lange nach, bis wir den gesamten Prozess im Blick haben und den wahren Bedarf kennen.</li>
            <li>Unsere ITler schulen in verständlicher Sprache auch nicht-technisch versierte Führungskräfte dafür, sich optimal mit den internen IT-Teams auszutauschen.</li>
            <li>Durch Berichte über den Fortschritt des Projekts bleiben Kunden stets informiert. Etablierte Formate sind Sprint Plannings, Reviews und Retrospektiven.</li>
            <li>Es ist wichtig, frühzeitig herausfinden, wo unrealistische Erwartungen im Aufwand unterschätzt werden und welche Änderungen zusätzliche Kosten verursachen könnten.</li>
          </ul>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/3.png" alt="Benutzer-getriebene Software-Entwicklung">
        </div>
      </div>
    </section>
    
    <Container :styles="'pt-16'">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-1/2 md:w-2/5 mr-32 inline-flex">
          <img class="object-contain" src="./../assets/img/howto/6.png" alt="Software Handwerkskunst">
        </div>
        <div class="w-full md:w-3/5 mt-6 mb-2">
          <h3 class="h3">Kommunikation</h3>
          <ul class="checklist">
            <li>Oft fehlt das Verständnis bezüglich des Projektumfangs verteilt auf seine verschiedenen Phasen. Da der Fortschritt nicht linear zu dem sichtbaren Ergebnis verläuft, muss sichergestellt werden, dass in technischen Phasen visuelle Ergebnisse den Fortschritt aufzeigen.</li>
            <li>Auftraggeber werden dann als kompliziert empfunden, wenn sie nicht angemessen geführt werden. Viele Iterationen und Prototypen helfen, dieselbe Vision zu formen und Missverständnisse zu vermeiden.</li>
            <li>Auftraggebern mit wenig bis keiner IT-Projekterfahrung fehlt oft das Hintergrundwissen über Best Practices und Prozesse. Nicht durchdachte Funktionen und hierauf basierende nachträgliche Änderungen führen zu Verzögerung. Wir hinterfragen die Anforderungen lieber einmal mehr.</li>
            <li>Verzögerungen im Projektfortschritt können trotz guter Arbeit des Entwicklerteams zu Unzufriedenheit und Unrentabilität führen. Durch regelmäßiges Feedback holen wir alle Stakeholder frühzeitig mit in den Loop.</li>
          </ul>
        </div>
      </div>
    </Container>

    <section>
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h3 class="h3">Visuelle Herangehensweise</h3>
          <ul class="checklist">
            <li>Eine visuelle Darstellung ist von Beginn an unerlässlich, um den Fokus auf die Kernanwendung zu legen. Das gilt bereits für den ersten Prototypen auf Papier sowie für das weitere Testen des Funktionsumfangs.</li>
            <li>Grafische Mockups, Klick-Dummys und Prototypen können hierbei helfen. Sie erlauben allen Beteiligten, sich bereits frühzeitig ein Bild vom Endprodukt zu machen und Feedback zu geben. Dies fördert das Vertrauen und die Kundenbindung. Zudem ist es einfacher, Features zu ändern, die noch nicht implementiert wurden, als bereits existierende Funktionen zu überarbeiten.</li>
            <li>Bedeutung des Testens: Gründliche Tests sind unerlässlich, um Fehler frühzeitig zu erkennen. Auftraggeber sollten nicht nur den Positiv-/Optimal-Weg testen, sondern so testen, dass sie Fehlverhalten provozieren.</li>
            <li>Einbeziehen des Endnutzers: Dieser sollte im Testprozess eingebunden werden, um sicherzustellen, dass die Software intuitiv und benutzerfreundlich ist.</li>
          </ul>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/2.png" alt="Lebenslanges Lernen bei Softwerkstatt">
        </div>
      </div>
    </section>

    <Container :styles="'pt-16'">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-1/2 md:w-2/5 mr-32 inline-flex">
          <img class="object-contain" src="./../assets/img/howto/4.png" alt="Freiräume und selbst Entscheidungen treffen">
        </div>
        <div class="w-full md:w-3/5">
          <h3 class="h3">Wording</h3>
          <ul class="checklist">
            <li>Klare und konsistente Kommunikation ist entscheidend. Traditionelle Anforderungsdokumente nach Wasserfall-Methode sind dabei nicht immer ideal.</li>
            <li>Einheitliche Sprache: Ein konsistentes Vokabular hilft, Missverständnisse zu vermeiden.</li>
            <li>Begriffslegende: Ein Glossar erleichtert die Kommunikation mit technischen und nicht-technischen Stakeholdern.</li>
            <li>Flexibilität in der Dokumentation: Nur das Wesentliche sollte dokumentiert werden, um Agilität zu bewahren. Mockups und Quellcode sollte die verständlichste Dokumentation darstellen.</li>
          </ul>
        </div>
      </div>
    </Container>

    <section class="pt-16">
      <div class="mx-auto max-w-screen-md xl:max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 flex flex-col md:flex-row">
        <div class="w-full md:w-3/5 mt-8 mb-16">
          <h3 class="h3">Zusammenarbeit</h3>
          <ul class="checklist">
            <li>Nachbetreuung ist genauso wichtig wie die eigentliche Projektarbeit. Software soll langfristig optimal nutzbar sein und auf Veränderungen im Geschäftsalltag reagieren können.</li>
            <li>Statt Fehler zu wiederholen, sollte aus ihnen gelernt werden. Gemeinsame Retrospektiv-Meetings stellen hilfreiche Weichen für den Lernprozess. </li>
            <li>Support nach Projektabschluss: Ein fortlaufender Support sichert die langfristige Funktionsfähigkeit der Software.</li>
            <li>Feedback-Schleifen: Konstruktives Feedback wird geschätzt und zur ständigen Verbesserung genutzt. Kunden werden in jeden Schritt des Entwicklungsprozesses einbezogen.</li>
          </ul>
        </div>
        <div class="w-1/2 md:w-2/5 ml-32 inline-flex order-first md:order-none">
          <img class="object-contain" src="./../assets/img/howto/1.png" alt="Programmierung im Team">
        </div>
      </div>
    </section>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <ContactInfos />
    </div>
    
    

  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'
import ContactInfos from './../views/ContactInfos.vue'

export default {
  name: 'LessonsLearned',
  components: {
    Headline,
    Container,
    ContactInfos
  },
  methods: {
  }
}
</script>

<style scoped>
</style>