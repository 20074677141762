<template>
  <section class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl pb-16 lg:pb-24 xl:pb-32 px-4 lg:px-0 text-center lg:mt-16">
    <!-- <div class="pre-headline">Heimathafen</div> -->
    <!-- <div class="h1">Jetzt mit uns in See stechen</div> -->
    <!-- <p class="w-full md:max-w-3xl mx-auto text-left sm:text-center">Speedboot statt Riesentanker: Ein Auszug unserer Lieblingskunden, bei denen wir glücklich sind, sie digitalisiert zu haben. Denn mit unserer Software sind sie schnell, effektiv und agil wie ein Kanu.</p> -->
    <div class="grid grid-cols-3 md:grid-cols-4 xl:grid-cols-8 justify-items-center robots-nocontent sm:mt-8 sm:mt-16 mx-4 sm:mx-0 lg:mb-24 md:mx-16 lg:mx-48 xl:mx-0 scale-105
    gap-x-6 sm:gap-x-12
    gap-y-8 sm:gap-y-16 md:gap-y-8"
    id="refContainer">
      <img src="./../assets/img/logos/fernsehlotterie.jpg" alt="Deutsche Fernsehlotterie" class="top-[1px] relative hidden md:block" />
      <img src="./../assets/img/logos/HannoverRe.svg" alt="Hannover Rück" />
      <img src="./../assets/img/logos/athlon.png" alt="Athlon" class="relative" />
      <img src="./../assets/img/logos/stroeer.png" alt="Ströer" class="top-[1px] left-1 relative" />
      <img src="./../assets/img/logos/trumpf.webp" alt="Trumpf" class="top-[1px] left-3 relative" />
      <img src="./../assets/img/logos/eckd.png" alt="ECKD - Dienstleistungen für Kirche & Wohlfahrt" class="top-[2px] left-6 relative" />
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 223.3 75.9" xml:space="preserve" class="-top-2 relative">
        <path d="M210.1,60.7l2.2,4l-1.9,3.6h1.1l4-7.7h-1.1l-1.5,3.1l-1.6-3.1L210.1,60.7L210.1,60.7z M203.6,64.9
        c-1,0-1.5-0.9-1.5-1.8c0-0.9,0.6-1.8,1.5-1.8c1,0,1.6,0.8,1.6,1.8C205.2,64.1,204.6,64.9,203.6,64.9 M205.2,64.8v0.9
        c0,1-0.2,1.8-1.4,1.8c-0.9,0-1.5-0.5-1.5-1.4h-0.9c0,1.4,1,2.2,2.4,2.2c1.9,0,2.4-1.2,2.4-2.9v-4.8h-0.9v0.8l0,0c-0.4-0.6-1-1-1.7-1
        c-1.4,0-2.3,1.2-2.3,2.6c0,1.4,0.8,2.6,2.3,2.6C204.2,65.8,204.8,65.4,205.2,64.8L205.2,64.8z M192.9,63.2c0-0.9,0.7-1.7,1.7-1.7
        c0.9,0,1.7,0.8,1.7,1.7c0,0.9-0.7,1.7-1.7,1.7C193.6,64.9,192.9,64.1,192.9,63.2 M191.9,63.2c0,1.5,1.1,2.6,2.6,2.6s2.6-1.1,2.6-2.6
        s-1.1-2.6-2.6-2.6C193.1,60.6,191.9,61.7,191.9,63.2 M177.8,63.2c0-0.9,0.7-1.7,1.7-1.7c0.9,0,1.7,0.8,1.7,1.7
        c0,0.9-0.7,1.7-1.7,1.7C178.6,64.9,177.8,64.1,177.8,63.2 M176.9,63.2c0,1.5,1.1,2.6,2.6,2.6s2.6-1.1,2.6-2.6s-1.1-2.6-2.6-2.6
        C178,60.6,176.9,61.7,176.9,63.2 M169.3,60.7h-0.9v4.9h0.9v-2.2c0-0.9,0-2,1.2-2c1,0,1.1,0.6,1.1,1.4v2.8h0.9v-2.9
        c0-1.2-0.4-2.1-1.7-2.1c-0.6,0-1.2,0.3-1.5,0.8l0,0V60.7z M150.8,63.2c0,1.5,1.2,2.6,2.6,2.6c0.5,0,0.9-0.1,1.3-0.3v-1.2
        c-0.4,0.4-0.8,0.6-1.3,0.6c-1,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.6-1.7c0.6,0,1,0.2,1.4,0.7V61c-0.4-0.2-0.9-0.3-1.3-0.3
        C152,60.6,150.8,61.7,150.8,63.2 M143.2,62.6c0.1-0.7,0.7-1.2,1.4-1.2c0.8,0,1.2,0.5,1.3,1.2H143.2z M142.2,63.2
        c0,1.4,0.9,2.6,2.4,2.6c1,0,1.8-0.5,2.3-1.3L146,64c-0.4,0.6-0.7,0.9-1.5,0.9c-0.9,0-1.5-0.8-1.5-1.6h3.7v-0.1
        c0-1.4-0.7-2.6-2.3-2.6C143,60.6,142.2,61.7,142.2,63.2 M136.6,60.7h-0.5v0.9h0.5v4h0.9v-4h0.9v-0.9h-0.9v-1.9h-0.9V60.7z
        M116.2,56.5h-0.9v9.1h0.9V56.5z M106.2,63.2c0-0.9,0.6-1.8,1.5-1.8c1,0,1.6,0.8,1.6,1.8c0,1-0.6,1.8-1.6,1.8
        C106.8,64.9,106.2,64.1,106.2,63.2 M105.3,63.1c0,1.4,0.8,2.6,2.3,2.6c0.7,0,1.3-0.4,1.7-0.9l0,0v0.8h0.9v-4.9h-0.9v0.8l0,0
        c-0.4-0.6-1-1-1.7-1C106.2,60.6,105.3,61.8,105.3,63.1 M92.2,60.7h-0.9v4.9h0.9V60.7z M91.1,58.5c0,0.4,0.3,0.6,0.6,0.6
        c0.4,0,0.6-0.3,0.6-0.6c0-0.4-0.3-0.7-0.6-0.7S91.1,58.1,91.1,58.5 M82.1,63.2c0-0.9,0.6-1.8,1.5-1.8c1,0,1.6,0.8,1.6,1.8
        c0,1-0.6,1.8-1.6,1.8C82.6,64.9,82.1,64.1,82.1,63.2 M81.2,63.1c0,1.4,0.8,2.6,2.3,2.6c0.7,0,1.3-0.4,1.7-0.9l0,0v0.8h0.9v-9.1h-0.9
        v5l0,0c-0.4-0.6-1-1-1.7-1C82,60.6,81.2,61.8,81.2,63.1 M37,51.9l7.7-24.2h-6.6l-4.6,15.7h-0.1l-4.8-15.7h-5l-5.1,15.7h-0.1
        l-4.2-15.7H7.9l7.2,24.2h6.4l4.8-14.1h0.1l4.2,14.1C30.6,51.9,37,51.9,37,51.9z M46.3,51.9h13.8v-5.3h-7.5v-4.2h7.1V37h-7.1v-4h7.5
        v-5.3H46.3V51.9z M63.3,60.6c-0.5,0-1,0.3-1.3,0.8l0,0v-0.6h-1v4.9h0.9v-2.4c0-0.8,0.1-1.8,1.1-1.8c0.9,0,0.9,1,0.9,1.6v2.6h0.9
        v-2.4c0-0.8,0.1-1.8,1.1-1.8c0.8,0,0.9,0.8,0.9,1.4v2.8h0.9v-2.9c0-1.1-0.3-2.1-1.6-2.1c-0.6,0-1.2,0.3-1.5,0.9
        C64.4,60.9,63.9,60.6,63.3,60.6 M63.8,51.9h6.3V27.7h-6.3V51.9z M74.7,61.4c0.8,0,1.2,0.5,1.3,1.2h-2.7C73.5,61.9,74,61.4,74.7,61.4
        M74.7,60.6c-1.6,0-2.4,1.1-2.4,2.6c0,1.4,0.9,2.6,2.4,2.6c1,0,1.8-0.5,2.3-1.3l-0.8-0.4c-0.4,0.6-0.7,0.9-1.5,0.9
        c-0.9,0-1.5-0.8-1.5-1.6H77v-0.1C77,61.7,76.3,60.6,74.7,60.6 M92.4,51.9h6.3V27.7h-6.3v14.8h-0.1L80.8,27.7h-6.3v24.2h6.3V37h0.1
        L92.4,51.9z M99.6,65.8c0.5,0,0.9-0.1,1.3-0.3v-1.2c-0.4,0.4-0.8,0.6-1.3,0.6c-1,0-1.7-0.7-1.7-1.7c0-0.9,0.7-1.7,1.6-1.7
        c0.6,0,1,0.2,1.4,0.7V61c-0.4-0.2-0.9-0.3-1.3-0.3c-1.5,0-2.7,1.1-2.7,2.6C97,64.7,98.2,65.8,99.6,65.8 M125.1,48.9l4.5-11.7
        l-1.4-9.5h-6.3l-5.2,13l-4.9-13h-6.2l-4.1,24.2h6.3l2-14h0.1l5.6,14h2.5l5.8-14h0.1L125.1,48.9z M127.9,65.6l15.9-41.7l-3.1-8.2
        l-19,49.9H127.9z M151.1,42.9h-5.3l2.6-6.9L151.1,42.9z M160.7,51.9l-9.1-24.2l4.6-12.1l-3.2-8L136.1,52h6.2l1.6-4.2h8.9l1.6,4.2
        L160.7,51.9L160.7,51.9z M161.7,60.6c-0.6,0-1.2,0.3-1.5,0.8l0,0v-4.8h-0.9v9.1h0.9v-2.2c0-0.9,0-2,1.2-2c1,0,1.1,0.6,1.1,1.4v2.8
        h0.9v-2.9C163.5,61.5,163.1,60.6,161.7,60.6 M180.2,51.9h6.3V27.7h-6.3v14.8h-0.1l-11.5-14.8h-6.3v24.2h6.3V37h0.1L180.2,51.9z
        M186.5,65.6h0.9v-9.1h-0.9V65.6z M208.8,51.9h6.3V27.7h-6.3v14.8h-0.1l-11.5-14.8h-6.3v24.2h6.3V37h0.1L208.8,51.9z"></path>
      </svg>
      <img src="./../assets/img/logos/weischer.jpg" alt="Weischer Media" class=" hidden md:block" />
    </div>
  </section>
</template>

<script>

export default {
  name: 'References',
  components: {
  },
  methods: {
    onClickCta() {
      this.$emit('clicked', 'Projekt starten')
    }
  }
}
</script>

<style scoped>
#refContainer > * {
  max-height: 55px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.2s;
}
/* @media (min-width: 640px) { */
  #refContainer > * {
    opacity: 0.7;
  }
  #refContainer > *:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
    opacity: 1;
    transform: scale(1.15);
  }
/* } */
</style>