<template>
  <div id="content">
    <Welcome2 />
    <References />
    <Intro />
    <ChangeHome />
    <Services6 />
    <div id="contactForm" class="">
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 text-gray-700 py-8 lg:py-16 xl:py-32">
        <HiringInfos />
        <ContactInfos />
      </div>
    </div>
  </div>
</template>

<script>
import Welcome2 from './../views/Welcome2.vue'
import Intro from './../views/Intro.vue'
import ChangeHome from '../views/ChangeHome.vue'
import Services6 from './../views/Services6.vue'
import References from './../views/References.vue'
import ContactInfos from './../views/ContactInfos.vue'
import HiringInfos from './../views/HiringInfos.vue'
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Welcome2,
    Intro,
    ChangeHome,
    Services6,
    References,
    ContactInfos,
    HiringInfos
  },
  watch: {
    '$route': {
      handler() {
        var trackingId = this.$route.params.trackingId
        if(trackingId) {
          console.log('Tracking-ID: ' + trackingId);
          this.sendForm(trackingId);
        }
      },
      immediate: true
    }
  },
  methods: {
    async sendForm(trackingId) {
      let data = { 'trackingId': trackingId };
      await axios.post('/.netlify/functions/saveTracking', data).then((res) => {
        console.log(res)
      }).catch((e) => {
        console.error(e)
      });
    }
  }
}
</script>

<style scoped>
#contactForm {
  background: linear-gradient(180deg, rgba(247, 250, 255, 0.0) 0%, rgba(38, 74, 102, 0.1) 50%, rgba(255, 255, 255, 0.0) 100%);

}
</style>