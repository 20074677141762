<template>
  <Headline :subtitle="'Qualitätsversprechen'" :useBorder2img="true">Unser Handwerk</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl  px-4 lg:px-0 mb-8">
      <div class="lg:text-center">
        <p class="inline-block p max-w-2xl pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„If you're good at the debugger it means you spent a lot of time debugging. I don't want you to be good at the debugger.”</i> ― Robert C. Martin</p>
      </div>
      <h2 class="h2">Unser Handwerk</h2>
      <p>Als leidenschaftliche Software-Handwerker heben wir die Messlatte für professionelle Softwareentwicklung an, indem unser Qualitätsanspruch ist, die Nachhaltigkeit und Langlebigkeit Eurer (und damit unserer) Software stetig zu verbessern. Es ist einfach, eine App oder Website zu entwickeln, die Spezifikationen erfüllt und damit der initialen Idee folgt. Aber weitaus schwieriger, sie nach den wirklichen Bedürfnissen der Benutzer zu gestalten, zu skalieren, eine unkomplizierte Weiterentwicklung zu gewährleisten, und so mit verändernden Anforderungen wachsen zu lassen.</p>
      <p>Wir sind uns sicher, dass es bessere Ansätze braucht, als es das Abarbeiten eines Pflichtenheft oder Dokumentationen erlauben. In einer Umgebung, der sich ständig verändernden Anforderungen, Nutzerfokussierung und agiler Projektorganisation, ist es unser Anspruch, die langfristige Qualität der Software nicht zu lasten schneller Ergebnisse zu opfern.</p>
      <p>Durch Learnings aus vorherigen Projekten haben wir zu schätzen gelernt, nicht nur auf funktionierende und benutzbare Software zu achten, sondern, dass ein hoher Qualitätsanspruch die Grundlage sein muss. Denn wir möchten wirklichen Mehrwert schaffen, statt nur auf Veränderungen zu reagieren. Darunter verstehen wir Disziplinen wie das Sicherstellen von Erweiterbarkeit, Wartbarkeit, Zuverlässigkeit, Testbarkeit (und Unit-Tests), Sicherheit, Effizienz, Fehlertoleranz, Wiederherstellbarkeit und Nachweisbarkeit.</p>
      <p>Natürlich kommt es immer drauf an, das eigentliche Problem zu lösen, bevor wir uns zu sehr ins Technische begeben und im Detail verlieren. Hierbei möchten wir die Art und Weise der Softwareentwicklung hervorheben: Denn sie besteht aus vielen schwer änderbaren Entscheidungen. Wichtig ist, dass die gesamte Zusammenarbeit ein interaktiver Prozess ist. Denn wir verstehen Kommunikation und Interaktionen als höhere Priorisierung vor Prozesse und Werkzeuge.</p>
    </div>

    <Container>
      <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl  px-4 lg:px-0">
        <h2 class="h2">Ziel</h2>
        <p>Unser Ziel ist es, dass Eure Software auch langfristig glücklich macht. Lieber möchten wir am Anfang mehr Gedanken investieren, anstatt später Einschränkungen zu unterliegen. Hiermit verhindern wir, dass ganze Plattformen nach einigen Jahren neu entwickelt werden müssen. Agile Vorgehensweisen wie Scrum, Kanban und Lean sind gute Werkzeuge, um in Kombination mit Prototypen und Feedback-Loops ständig das Look &amp; Feel zu verbessern.</p>
        <p>Besonders das Reagieren auf Veränderung und der Fokus auf Benutzer-Feedback schätzen wir als wichtig ein. Dies gewährleistet ein optimales Kosten/Nutzen Verhältnis, und Ihr lassen keine Features entwickeln, die sie am Ende doch nicht benötigen. Um mit unserer Strategie auch langfristig erfolgreich zu sein, liegt uns der Aufbau von produktiven Partnerschaften, einem Netzwerk von Experten und stetigen Steigerung unserer Inhouse-Expertise sehr am Herzen.</p>
        <p>Obwohl wir sagen, unser Tech Stack ist in einem gewissen Bereich, versuchen wir, je nach Projekt, immer die passenden Technologien zu finden. Dabei stehen wir neuen Ansätze, Programmiersprachen und Frameworks offen und flexibel gegenüber.</p>
        
      </div>
    </Container>

    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl  px-4 lg:px-0 mb-16">
      
      <!-- <h2 class="h2">Historie</h2>
      <p>
        Seit neun Jahren zeichen uns die selbe Vision mit einer sich ständig verändernder Strategie aus.
        Unsere B2C und Agentur Wurzeln lehrten uns, dass Softwareentwicklung einer schnellen Einführung eines MVC und Prototypen bedaf, jedoch auch, dass nicht erreichte KPIs mit einem kurzen Productlebenszyklen einhergehen.
        Wir glauben an an einen Prozess, in dem das Handwerk an oberster Stelle stehen kann, um mit Qualität zu überzeugen. Ein Prozess, bei dem wir nicht mit Leib und Seele dahinter stehen. 
      </p>
        
      <p>
        <span class="bg-yellow-400">TBD</span>
        Unsere Erfahrung lehrte uns, einen Fokus auf interner Unternehmens-Software zu setzen, wo wir unsere Stärken besser ausspielen können.
        Wir glauben, unsere Erfahrungen geben uns BLA, um schnell die Erwartungen übertreffen zu können, ohne Kompromisse bei dem Qualitätsversprechen eingehen zu müssen
        Damit schaffen wir eine Unternehmenskultur, bei der wir unseren Softwareentwicklern versprehcen, sich auf hochwertige Programmierung zu fokussieren, ohne durch kurzfristigen KPIs abgelenkt zu werden. Mit dieser Vision fühlen wir uns seither in der täglichen Arbeit glücklich und dies beweisen auch unsere Kundenbeziehungen und Projekte.
      </p> -->

      <ContactInfos />
    </div>

  </div>
</template>

<script>
import Headline from './../views/Headline.vue'
import Container from './../views/Container.vue'
import ContactInfos from './../views/ContactInfos.vue'

export default {
  name: 'Handwerk',
  components: {
    Headline,
    Container,
    ContactInfos
  },
  methods: {
  }
}
</script>

<style scoped>
.bg-white {
  background-color: #fff;
}
p {
  margin: 1rem 0;
}
</style>