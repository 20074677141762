<template>
  <Headline :subtitle="'Die besten Projekte + die besten Entwickler'" :useBorder2img="true">It’s a match!</Headline>
  <div class="pt-4 md:pt-8 xl:pt-16 pb-32 lg:my-5">
    <div class="container mx-auto max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0 mb-8">
      
      <div class="sm:text-center">
        <p class="inline-block p max-w-md md:max-w-lg pb-4 sm:pb-8 md:pb-12 mx-auto"><i>„Talent wins games, but teamwork and intelligence win championships”.</i> ― Michael Jordan, basketball player</p>
      </div>

      <ul id="itsAMatch" class="cursor-default sm:mt-4 xl:mt-16">
        <li class="transform transition-all hover:bg-gray-100 sm:my-4 sm:rounded-lg" v-for="entry in values" :key="entry.title">
          <img :src="'/icons/' + entry.icon" alt="" class="h-14 sm:h-16 absolute lg:left-8 xl:left-40" :class="{'lg:mt-3': entry.linesTitle == 2}" />
          <h2 class="h2 sm:pt-3 ml-16 lg:ml-32 xl:ml-64 lg:max-w-[calc(100%-8rem-4rem)] xl:max-w-3xl">{{ entry.title }}</h2>
          <p :class="'transform transition-all lg:ml-32 xl:ml-64 px-2 sm:px-0 lg:max-w-[calc(100%-8rem-4rem)] xl:max-w-3xl lines' + entry.lines">{{ entry.text }}</p>
        </li>
      </ul>
      
    </div>

  </div>
</template>

<script>
import Headline from './../views/Headline.vue'

export default {
  name: 'ItsAMatch',
  components: {
    Headline,
  },
  data: () => {
    return {
      values: [
        {
          title: 'Du willst nicht jahrelang für die selbe Firma arbeiten?',
          text: 'Bei Softwerkstatt unterstützt du alle 6-18 Monate ein anderes, spannendes Unternehmen. Werde ein Consultant, wenn Du ein “Entwickler-Freigeist” bist und es die Abwechslungen und die fachlichen Herausforderungen sind, die Dich jeden Tag motivieren.',
          icon: '11.webp',
          linesTitle: 1,
          lines: 3
        },
        {
          title: 'Du willst mit den besten Softwareentwicklern arbeiten und von ihnen lernen?',
          text: 'Unsere Kunden holen Dich als Experte für ihre wichtigsten Prestigeprojekte an Bord. Zusammen mit weiteren erfahrenen Seniorentwicklern des Kunden nutzt ihr die Synergien eurer Kompetenzen, um das beste Ergebnis zu erzielen, aber auch voneinander zu lernen und Neues zu erfahren.',
          icon: '3.webp',
          linesTitle: 2,
          lines: 4
        },
        {
          title: 'Du willst endlich all die Zeit haben, eigene Projekte und Ideen umzusetzen?',
          text: 'Weiterbilden und Lernen ist als Start-Up fest in unserer DNA verankert. Jeden zweiten Freitag legen wir alle Kundenprojekte beiseite und arbeiten konzentriert an den großartigen Ideen aus unseren privaten Schubladen. Dabei gibt dir keine Vorgaben, was du machen sollst. Nutze diesen Tag komplett eigenständig.',
          icon: '6.webp',
          linesTitle: 2,
          lines: 4
        },
        {
          title: 'Dir fällt es schwer, an spannende und moderne Projekte zu kommen?',
          text: 'Wenn renommierte Unternehmen ihren bedeutendsten Projekten höchste Priorität geben, kommt es häufig vor, dass sie externe Verstärkung suchen. Da große Unternehmen oft Rahmenverträge mit externen Dienstleistern bevorzugen, fällt es schwer, als Freelancer hier ranzukommen. Du hast mit uns die Chance, als "interner Externer" für die besten Unternehmen State of the Art Softwarelösungen zu entwickeln.',
          icon: '9.webp',
          linesTitle: 2,
          lines: 5
        },
        {
          title: 'Obstkorb und Kickertisch gibt’s auch bei dir Zuhause?',
          text: 'Arbeiten bei Softwerkstatt bedeutet, sich von starren Strukturen und ausgedienten Arbeitsmodellen zu verabschieden. Wir leben “New Work” und verhandeln zusammen immer neue Arbeitsweisen aus. Du arbeitest am liebsten zu Hause? Oder in Deinem Ferienhaus in der Provence? Kein Problem, du bestimmst selbst, wann du wo arbeitest.',
          icon: '4.webp',
          linesTitle: 1,
          lines: 4
        },
        {
          title: 'Du willst alle Freiheiten, aber auch monetären peace of mind?',
          text: 'Aus eigener Erfahrung wissen wir um den höheren Stundensatz als Freelancer, aber auch die Herausforderung, immer an eine schwankende Auftragslage gebunden zu sein. Wir bieten dir das Beste aus der Freelancer- und der Angestellten-Welt: Abwechslungsreiche Projekte bei regelmäßig wechselnden Kunden und gleichzeitig einen fetten monatlichen Gehaltsscheck, der dir Sicherheit gibt.',
          icon: '7.webp',
          linesTitle: 2,
          lines: 5
        },
        {
          title: 'Du hast schon genug Legacy Code gesehen?',
          text: 'Als externer Dienstleister sind wir nicht an einen Auftraggeber gebunden, sondern suchen auf dem gesamten Markt nach den attraktivsten Grüne-Wiese-Projekten, die genau auf Deine Skills und Branchen-Expertise passen. Und solltest du doch nicht um Legacy Code herum kommen, setzen wir uns dafür ein, Dir spannende Projekten mit viel Spielraum zu bieten.',
          icon: '15.webp',
          linesTitle: 1,
          lines: 4
        },
        {
          title: 'Du willst selbstständig agieren, aber auch Teil eines starken Teams sein, das dir Rückendeckung gibt?',
          text: 'Uns ist viel daran gelegen, dass unsere Mitarbeitenden und Kunden ein perfektes Match abgeben. Dabei machen wir keine Kompromisse. Sollte es zu unerfüllten Erwartungen kommen, sind wir die Schnittstelle zwischen dir und den Ansprechpartnern des Kunden. Sollte die Zusammenarbeit also nicht passen, kannst du dich zurücklehnen und uns die Arbeit überlassen, dir anderes Projekt zu suchen, bei dem Du zufriedener bist.',
          icon: '9.webp',
          linesTitle: 2,
          lines: 5
        },
        {
          title: '„Einmal mit Profis arbeiten...” ?',
          text: 'Wer die beste Software entwickeln will, der braucht finanzielle Freiheiten für die nötige Technologie. Nimm einfach deine Firmenkreditkarte in die Hand und stelle deinen Arbeitsplatz so zusammen, wie Du ihn für optimalen Output benötigst. Du stellst dir deine Hard- und Software selbst zusammen, sei es die Apple Vision Pro, einen höhenverstellbaren Schreibtisch oder Tools wie Copilot und ChatGPT.',
          icon: '8.webp',
          linesTitle: 1,
          lines: 5
        },
      ]
    }
  },
  methods: {
    onClick() {
      this.$router.push({name: 'jobs'})
      this.scrollToTop()
    }
  }
}
</script>

<style scoped>
#itsAMatch > li {
  transition: all 0.6s;
  padding: 1.0rem 0;
}
#itsAMatch > li:hover {
  transform: scale(1.02);
}
#itsAMatch > li > p {
  height: 0;
  overflow: hidden;
}
#itsAMatch > li:hover > p.lines3 {
  height: 95px;
}
#itsAMatch > li:hover > p.lines4 {
  height: 125px;
}
#itsAMatch > li:hover > p.lines5 {
  height: 155px;
}

@media (max-width: 640px) {
  li {
    border-bottom: 2px solid #6EA5CC;
    margin-bottom: 4px;
  }
  h2.h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.3em;
    line-height: 1.4em;
  }
  li:last-child h2.h2 {
    margin-bottom: 1.5rem;
  }
  #itsAMatch > li > p.lines3,
  #itsAMatch > li > p.lines4,
  #itsAMatch > li > p.lines5 {
    line-height: 1.3em;
  }
  #itsAMatch > li:hover > p.lines3,
  #itsAMatch > li:hover > p.lines4,
  #itsAMatch > li:hover > p.lines5 {
    height: auto;
    padding-top: 1rem;
  }
}
@media (min-width: 641px) and (max-width: 768px) {
  h2.h2 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4.5rem;
    font-size: 1.3em;
    line-height: 1.4em;
  }
  #itsAMatch > li > p.lines3,
  #itsAMatch > li > p.lines4,
  #itsAMatch > li > p.lines5 {
    line-height: 1.2em;
  }
  #itsAMatch > li:hover > p.lines3,
  #itsAMatch > li:hover > p.lines4,
  #itsAMatch > li:hover > p.lines5 {
    height: auto;
    padding-top: 1rem;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  h2.h2 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 4.5rem;
    padding: 0;
    font-size: 1.6em;
  }
  li:last-child h2.h2 {
    margin-top: 1rem;
  }
  #itsAMatch > li > p.lines3,
  #itsAMatch > li > p.lines4,
  #itsAMatch > li > p.lines5 {
    line-height: 1.2em;
  }
  #itsAMatch > li:hover > p.lines3,
  #itsAMatch > li:hover > p.lines4,
  #itsAMatch > li:hover > p.lines5 {
    height: auto;
    padding-top: 1rem;
  }
}
</style>