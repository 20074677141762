<template>
  <article class="py-6 sm:py-0 pb-4 overflow-hidden mt-8 2xl:mt-16 mb-32" id="services6">
    
    <div class="container mx-auto text-center max-w-screen-lg 2xl:max-w-screen-xl px-4 lg:px-0">
      <h2 class="pre-headline">Wir navigieren Euch durch die Transformation</h2>
      <div class="h1">Digitalisierung auf allen Decks</div>
      <div class="lg:flex">
        <div class="w-full lg:w-5/12 2xl:w-6/12 2xl:pr-16">
          <div class="scale-[0.6] sm:scale-100 origin-top-left w-96 h-48 sm:h-80 relative">
            <div class="w-80 h-80 rounded-full border-4 border-[#60BCB5] absolute left-0 top-0 z-20 hover:z-40 transform transition-all scale-103 bg-white/60 hover:bg-white/80 hover:border-[#428BB0]">
              <img src="./../assets/img/icon3.webp" alt="" class="h-36 absolute left-16 top-20" />
              <p class="absolute left-12 top-56 h-0"><router-link to="/change-management-hamburg" v-on:click="scrollToTop()" class="no-underline hover:underline text-[#6EA5CC] hover:text-[#0291D8]">Change Management</router-link></p>
            </div>
            <div class="w-80 h-80 rounded-full border-4 border-[#60BCB5] absolute left-60 top-0 z-10 hover:z-40 transform transition-all scale-103 hover:bg-white/80 hover:border-[#428BB0]">
              <img src="./../assets/img/icon4.webp" alt="" class="h-36 absolute left-28 top-20" />
              <p class="absolute left-28 top-56 h-0"><router-link to="/individualsoftware-hamburg" v-on:click="scrollToTop()" class="no-underline hover:underline text-[#6EA5CC] hover:text-[#0291D8]">Individualsoftware</router-link></p>
            </div>
            <div class="w-[21rem] h-[21rem] rounded-full border-8 border-white absolute left-[14.5rem] top-[-0.5rem] z-30 transform transition-all scale-103 pointer-events-none">
            </div>
          </div>
        </div>
        <div class="lg:w-7/12 2xl:w-6/12 2xl:pr-16 text-left">
          <p class="my-4">Wir befähigen Euch als Change Manager bei den großen Veränderungsvorhaben im Unternehmen oder unterstützen Euch "all hands on deck" als Softwareentwickler.</p>
          <p class="my-4">Dieser 360°-Blick machen Softwerkstatt unschlagbar im Bereich Digitale Transformation. Als Experten für Veränderungsvorhaben befähigen wir Euch für die großen Changeprozesse im Unternehmen oder unterstützen auch ganz projektspezifisch als Softwareentwickler Euer Inhouse-Team.</p>
          <p class="my-4">Welche digitalen Herausforderungen auch vor Euch stehen – wir begleiten Euch mit maßgeschneiderten Lösungen auf diesem Weg.</p>
        </div>
      </div>
    </div>

  </article>
</template>

<script>

export default {
  name: 'ChangeHome',
  components: {
  },
  data: () => {
    return {
      isMore: false
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0)
    }
  }
}
</script>

<style scoped>
div > .h-0 {
  transition: all 0.4s;
  opacity: 0;
}
div:hover > .h-0 {
  opacity: 1;
}
</style>